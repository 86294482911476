import { useState } from 'react';

export function FilterablePanelsTable({ panels, setSelectedCardAndOpenModal }) {
    const [filterText, setFilterText] = useState('');

    return (
        <div className="w-full h-full m-0">
            <SearchBar
                filterText={filterText}
                onFilterTextChange={setFilterText} />
            <br></br>
            <PanelTable
                panels={panels}
                filterText={filterText}
                setSelectedCardAndOpenModal={setSelectedCardAndOpenModal} />
        </div>
    );
}

function PanelRow({ panel, setSelectedCardAndOpenModal }) {
    return (
        <tr className="hover:bg-gray-50">
            <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">{panel.code}</td>
            <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
                <button className="underline" onClick={() => setSelectedCardAndOpenModal(panel.code)}>{panel.name}</button>
            </td>
        </tr>
    );
}

function PanelTable({ panels, filterText, setSelectedCardAndOpenModal }) {
    const rows = [];
    const lowerCaseFilter = filterText.toLowerCase();
    panels.forEach((panel) => {
        if (panel.name.toLowerCase().indexOf(lowerCaseFilter) === -1 &&
            panel.code.toLowerCase().indexOf(lowerCaseFilter) === -1) {
            return;
        }
        rows.push(
            <PanelRow
                panel={panel}
                key={panel.code}
                setSelectedCardAndOpenModal={setSelectedCardAndOpenModal} />
        );
    });

    return (
        <table className="order-collapse table-auto w-full text-sm">
            <thead className="bg-gray-200 dark:bg-slate-700">
                <tr>
                    <th className="w-24 border border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left">Code</th>
                    <th className="border border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left">Name</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
    );
}

function SearchBar({ filterText, onFilterTextChange }) {
    return (
        <form className="w-full">
            <input
                className="indent-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                type="text"
                value={filterText} placeholder="Search by name or code..."
                onChange={(e) => onFilterTextChange(e.target.value)} />
        </form>
    );
}