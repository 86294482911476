import { Dialog } from '@headlessui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { motion } from "framer-motion";
import lavenderTube from "./media/lavender_tube.png"
import redTube from "./media/red_tube.png"
import tigerAndGold from "./media/tiger_and_gold.png"
import greenTube from "./media/green_tube.png"
import lightBlueTube from "./media/light_blue_tube.png"
import darkBlueTube from "./media/dark_blue_tube.png"
import urineCollections from "./media/urine_collections.png"
import urineCulture from "./media/urine_culture.png"
import eSwab from "./media/e_swab.png"
import vtm from "./media/vtm.png"
import aerobicCultureSwab from "./media/aerobic_culture_swab.png"
import urineCupAndYellowUrineTube from "./media/urine_cup_and_yellow_urine_tube.png"
import stoolContainer from "./media/stool_containers.png"
import thinPrepVial from "./media/thin_prep_vial.png"

function Modal({ isOpen, setIsOpen, panel, selectedCard }) {

    if (!panel)
        return null

    return (
        <Dialog
            as={motion.div}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            layoutId={selectedCard}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}

            className="relative z-50">
            <div className="fixed inset-0 bg-black/60" aria-hidden="true" />
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 sm:p-6">
                    <Dialog.Panel className="h-full rounded-xl bg-white w-full lg:w-4/5 2xl:w-3/4 p-4 sm:p-6 lg:p-10">
                        <Dialog.Title className="text-xl font-medium text-gray-900">
                            <div className="relative h-full w-full">
                                <div onClick={() => setIsOpen(false)} className="absolute cursor-pointer top-0 right-0 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Close menu</span>
                                    <FontAwesomeIcon className="h-3 w-3 sm:h-5 sm:w-5" icon={faClose} />
                                </div>
                            </div>
                            <div>
                                <motion.h2>
                                    {panel.name}
                                </motion.h2>

                            </div>
                            <br></br>
                        </Dialog.Title>
                        <Dialog.Description className="text-gray-800">
                            <TestContainer panel={panel} />

                            <div className="grid grid-cols-12 gap-4">
                                <hr className="col-span-12"></hr>
                                <div className="col-span-3 text-lg">
                                    Code
                                </div>
                                <div className="col-span-9">
                                    {panel.code}
                                </div>
                                <hr className="col-span-12"></hr>
                                <div className="col-span-3 text-lg">
                                    Test Includes
                                </div>
                                <div className="col-span-9">
                                    {panel.analytes}
                                </div>
                                <hr className="col-span-12"></hr>

                            </div>

                        </Dialog.Description>
                        {/* This hidden button is for avoid autofocus on the anchor */}
                        <button type="hidden" autoFocus={true} />
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog >
    )
}

function Container({ container }) {
    return (
        <img className="h-12 mb-4" src={container} alt="Container" />
    );
}

function TestContainer({ panel }) {
    if (!panel.containers)
        return;

    let splitContainers = panel.containers.split(";");
    if (splitContainers.length === 0)
        return;

    let containers = [];
    splitContainers.forEach((container) => {
        switch (container) {
            case "green_tube":
                containers.push(<Container container={greenTube} />);
                break;
            case "light_blue_tube":
                containers.push(<Container container={lightBlueTube} />);
                break;
            case "dark_blue_tube":
                containers.push(<Container container={darkBlueTube} />);
                break;
            case "urine_collections":
                containers.push(<Container container={urineCollections} />);
                break;
            case "urine_culture":
                containers.push(<Container container={urineCulture} />);
                break;
            case "e_swab":
                containers.push(<Container container={eSwab} />);
                break;
            case "vtm":
                containers.push(<Container container={vtm} />);
                break;
            case "aerobic_culture_swab":
                containers.push(<Container container={aerobicCultureSwab} />);
                break;
            case "urine_cup_and_yellow_urine_tube":
                containers.push(<Container container={urineCupAndYellowUrineTube} />);
                break;
            case "stool_container":
                containers.push(<Container container={stoolContainer} />);
                break;
            case "thin_prep_vial":
                containers.push(<Container container={thinPrepVial} />);
                break;
            case "lavender_tube":
                containers.push(<Container container={lavenderTube} />);
                break;
            case "red_tube":
                containers.push(<Container container={redTube} />);
                break;
            case "tiger_and_gold_tube":
                containers.push(<Container container={tigerAndGold} />);
                break;
            default:
                break;
        }
    });

    if (!containers.length === 0)
        return

    return (
        <div>
            {containers}
        </div>
    );
}

export default Modal