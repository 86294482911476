import collectionPage1 from './media/collection_guide_page_1.png'
import collectionPage2 from './media/collection_guide_page_2.png'

export function CollectionGuide() {
    return (
        <div>
            <img src={collectionPage1} alt="Collection Guide" />
            <img src={collectionPage2} alt="Collection Guide" />
        </div>
    );
}