const Panels = [
    {
        "code": "170001",
        "name": "17-OH Progesterone LCMS",
        "containers": "",
        "analytes": "17-OH Progesterone LCMS Ref"
    },
    {
        "code": "150001",
        "name": "2-Hydroxyethylflurazepam",
        "containers": "",
        "analytes": "2-Hydroxyethylflurazepam"
    },
    {
        "code": "170257",
        "name": "25-Hydroxy Vitamin D (D2+D3 Fractionated)",
        "containers": "",
        "analytes": "25-Hydroxy Vitamin D (D2+D3 Fractionated) Ref"
    },
    {
        "code": "150007",
        "name": "6-MAM (Heroin met.)",
        "containers": "",
        "analytes": "6-MAM (Heroin met.)"
    },
    {
        "code": "160001",
        "name": "6-MAM (Heroin met.) - Oral",
        "containers": "",
        "analytes": "6-MAM (Heroin met.) - Oral"
    },
    {
        "code": "150008",
        "name": "7-Aminoclonazepam",
        "containers": "",
        "analytes": "7-Aminoclonazepam"
    },
    {
        "code": "160002",
        "name": "7-Aminoclonazepam - Oral",
        "containers": "",
        "analytes": "7-Aminoclonazepam - Oral"
    },
    {
        "code": "170012",
        "name": "a-Fetoprotein (AFP) And AFP-L3",
        "containers": "",
        "analytes": "a-Fetoprotein (AFP) And AFP-L3 Ref"
    },
    {
        "code": "150012",
        "name": "A-Hydroxyalprazolam",
        "containers": "",
        "analytes": "A-Hydroxyalprazolam"
    },
    {
        "code": "160003",
        "name": "A-Hydroxyalprazolam - Oral",
        "containers": "",
        "analytes": "Alpha-Hydroxyalprazolam - Oral"
    },
    {
        "code": "150054",
        "name": "A-Hydroxymidazolam",
        "containers": "",
        "analytes": "A-Hydroxymidazolam"
    },
    {
        "code": "150055",
        "name": "A-Hydroxytriazolam",
        "containers": "",
        "analytes": "A-Hydroxytriazolam"
    },
    {
        "code": "170002",
        "name": "AAT Mutation Analy",
        "containers": "",
        "analytes": "Aat Mutation Analy Ref"
    },
    {
        "code": "500042",
        "name": "Abdominal Disease Profile",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Amylase; Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chloride; Cholesterol; Clarity; Color; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Gamma-Glutamyl Transferase (GGT); Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; H. Pylori Abs, IgG Qualitative; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Hepatitis A Ab IgM; Hepatitis A Ab Total (IgG & IgM); Hepatitis B Core Ab Total; Hepatitis B Core Ab, IgM; Hepatitis B Surface Ab; Hepatitis B Surface Ag; Hepatitis C Virus Ab; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lipase; Lymphocytes Absolute; Lymphocytes Percentage; Magnesium; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Mucous; Neutrophils Absolute; Neutrophils Percentage; Nitrite; pH, Urine; Platelets Count; Potassium; Protein, Urine; RDW; Red Blood Cell, Urine; Red Blood Cells; Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Thyroid Stimulating Hormone (TSH); Total Protein; Triglycerides; Urobilinogen; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "170005",
        "name": "ABO Group And Rh Type",
        "containers": "lavender_tube",
        "analytes": "ABO Group And Rh Type Ref"
    },
    {
        "code": "170006",
        "name": "Acetaminophen",
        "containers": "",
        "analytes": "Acetaminophen Ref"
    },
    {
        "code": "170007",
        "name": "Acetylcholine Receptor (AChR) Binding Abs",
        "containers": "",
        "analytes": "Acetylcholine Receptor (AChR) Binding Abs Ref"
    },
    {
        "code": "170008",
        "name": "Acetylcholine Receptor (AChR) Blocking Abs",
        "containers": "",
        "analytes": "Acetylcholine Receptor (AChR) Blocking Abs Ref"
    },
    {
        "code": "170347",
        "name": "Acid-fast Mycobacteria Smear and Culture W/Reflex",
        "containers": "",
        "analytes": "Acid-fast Mycobacteria Culture Ref; Acid-fast Mycobacteria Smear Ref"
    },
    {
        "code": "140158",
        "name": "Acinetobacter baumanni",
        "containers": "",
        "analytes": "Acinetobacter Baumannii"
    },
    {
        "code": "140080",
        "name": "Acinetobacter Baumannii",
        "containers": "",
        "analytes": "Acinetobacter Baumannii"
    },
    {
        "code": "140060",
        "name": "Acinetobacter Calcoaceticus-Baumannii Complex",
        "containers": "",
        "analytes": "Acinetobacter Calcoaceticus-Baumannii Complex"
    },
    {
        "code": "170009",
        "name": "ACTH",
        "containers": "",
        "analytes": "ACTH Ref"
    },
    {
        "code": "170010",
        "name": "Actin Ab IgG",
        "containers": "",
        "analytes": "Actin Ab IgG Ref"
    },
    {
        "code": "170311",
        "name": "Acute Myelocytic Leukemia (AML) Profile, FISH",
        "containers": "",
        "analytes": "Acute Myelocytic Leukemia (AML) Profile, FISH Ref"
    },
    {
        "code": "170287",
        "name": "Adalimumab and Anti-Adalimumab Ab",
        "containers": "",
        "analytes": "Adalimumab and Anti-Adalimumab Ab Ref"
    },
    {
        "code": "140151",
        "name": "Adenovirus",
        "containers": "",
        "analytes": "Adenovirus"
    },
    {
        "code": "140036",
        "name": "Adenovirus",
        "containers": "",
        "analytes": "Adenovirus"
    },
    {
        "code": "170343",
        "name": "Aerobic Bacterial Culture, General",
        "containers": "",
        "analytes": "Aerobic Bacterial Culture, General Ref"
    },
    {
        "code": "140081",
        "name": "Aerococcus Urinae",
        "containers": "",
        "analytes": "Aerococcus Urinae"
    },
    {
        "code": "100001",
        "name": "Alanine Aminotransferase (ALT)",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT)"
    },
    {
        "code": "100002",
        "name": "Albumin",
        "containers": "tiger_and_gold_tube",
        "analytes": "Albumin"
    },
    {
        "code": "170288",
        "name": "Albumin, 24-Hr Urine",
        "containers": "",
        "analytes": "Albumin, Urine mg/day Ref; Albumin, Urine Ref"
    },
    {
        "code": "550007",
        "name": "Alcohol",
        "containers": "",
        "analytes": "Ethyl Sulfate (ETS); Ethyl-B-D-Glucuronide (ETG)"
    },
    {
        "code": "170014",
        "name": "Aldolase",
        "containers": "",
        "analytes": "Aldolase Ref"
    },
    {
        "code": "170015",
        "name": "Aldosterone LCMS",
        "containers": "",
        "analytes": "Aldosterone LCMS Ref"
    },
    {
        "code": "170264",
        "name": "Aldosterone: Renin Ratio",
        "containers": "",
        "analytes": "Aldosterone (Ratio) Ref; Aldosterone: Renin Ratio Ref; Renin Activity, Plasma Ref"
    },
    {
        "code": "100003",
        "name": "Alkaline Phosphatase (ALP)",
        "containers": "",
        "analytes": "Alkaline Phosphatase (ALP)"
    },
    {
        "code": "170016",
        "name": "Alkaline Phosphatase Isoenzymes",
        "containers": "",
        "analytes": "Alkaline Phosphatase Isoenzymes Ref"
    },
    {
        "code": "170210",
        "name": "Allergen Profile Plus, IgE (Ped)",
        "containers": "",
        "analytes": "Allergen Profile Plus, IgE (Ped) Ref"
    },
    {
        "code": "170328",
        "name": "Allergen Profile, Food - Nuts",
        "containers": "",
        "analytes": "Allergen Profile, Food - Nuts Ref"
    },
    {
        "code": "170013",
        "name": "Alpha-Fetoprotein (AFP), Tumor Marker",
        "containers": "tiger_and_gold_tube",
        "analytes": "α-Fetoprotein (AFP), Tumor Marker Ref"
    },
    {
        "code": "170332",
        "name": "Alpha1-Antitrypsin (A1AT)",
        "containers": "",
        "analytes": "Alpha1-Antitrypsin (A1AT) Ref"
    },
    {
        "code": "170333",
        "name": "Alpha1-Antitrypsin Phenotyping (A1AP)",
        "containers": "",
        "analytes": "Alpha1-Antitrypsin Phenotyping (A1AP) Ref"
    },
    {
        "code": "150013",
        "name": "Alprazolam",
        "containers": "",
        "analytes": "Alprazolam"
    },
    {
        "code": "160004",
        "name": "Alprazolam - Oral",
        "containers": "",
        "analytes": "Alprazolam - Oral"
    },
    {
        "code": "170236",
        "name": "Aluminum, Serum or Plasma",
        "containers": "",
        "analytes": "Aluminum, Serum or Plasma Ref"
    },
    {
        "code": "500015",
        "name": "Amenorrhea Menstrual Disorder Panel Bundle",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chloride; Cholesterol; Clarity; Color; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Ferritin; Follicle-Stimulating Hormone (FSH); Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; HCG, Total, Quantitative; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Iron Total; Ketone; LDL Cholesterol(Calculated); Leukocytes; Luteinizing Hormone (LH); Lymphocytes Absolute; Lymphocytes Percentage; Magnesium; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Mucous; Neutrophils Absolute; Neutrophils Percentage; Nitrite; pH, Urine; Platelets Count; Potassium; Prolactin; Protein, Urine; RDW; Red Blood Cell, Urine; Red Blood Cells; Sedimentation Rate, Westergren (ESR); Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Total Iron Binding Capacity (TIBC); Total Protein; Transferrin; Triglycerides; Triiodothyronine (T3); Urobilinogen; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "170017",
        "name": "AMH, Female",
        "containers": "",
        "analytes": "AMH, Female Ref"
    },
    {
        "code": "170251",
        "name": "Amino Acid Analysis, Limited, Plasma",
        "containers": "",
        "analytes": "Amino Acid Analysis, Limited, Plasma Ref"
    },
    {
        "code": "170259",
        "name": "Amiodarone (Cordarone)",
        "containers": "",
        "analytes": "Amiodarone (Cordarone) Ref"
    },
    {
        "code": "150014",
        "name": "Amitriptyline",
        "containers": "",
        "analytes": "Amitriptyline"
    },
    {
        "code": "170213",
        "name": "Ammonia, Plasma",
        "containers": "",
        "analytes": "Ammonia, Plasma Ref"
    },
    {
        "code": "150015",
        "name": "Amo-Pentobarbital",
        "containers": "",
        "analytes": "Amo-Pentobarbital"
    },
    {
        "code": "150175",
        "name": "Amphetamine",
        "containers": "",
        "analytes": "Amphetamine - Cup"
    },
    {
        "code": "150016",
        "name": "Amphetamine",
        "containers": "",
        "analytes": "Amphetamine"
    },
    {
        "code": "150162",
        "name": "Amphetamine - Actual",
        "containers": "",
        "analytes": "Amphetamine - EIA"
    },
    {
        "code": "150150",
        "name": "Amphetamine - EIA",
        "containers": "",
        "analytes": "Amphetamine - EIA"
    },
    {
        "code": "160005",
        "name": "Amphetamine - Oral",
        "containers": "",
        "analytes": "Amphetamine - Oral"
    },
    {
        "code": "550016",
        "name": "Amphetamines",
        "containers": "",
        "analytes": "Amphetamine; Methamphetamine"
    },
    {
        "code": "560002",
        "name": "Amphetamines -Oral",
        "containers": "",
        "analytes": "Amphetamine - Oral; Methamphetamine - Oral"
    },
    {
        "code": "100004",
        "name": "Amylase",
        "containers": "tiger_and_gold_tube",
        "analytes": "Amylase"
    },
    {
        "code": "170299",
        "name": "Anaerobic and Aerobic Culture",
        "containers": "",
        "analytes": "Anaerobic and Aerobic Culture Ref"
    },
    {
        "code": "170336",
        "name": "Anaerobic and Aerobic Culture and Gram Stain",
        "containers": "",
        "analytes": "Anaerobic and Aerobic Culture and Gram Stain Ref"
    },
    {
        "code": "170018",
        "name": "ANCA",
        "containers": "tiger_and_gold_tube",
        "analytes": "ANCA Ref"
    },
    {
        "code": "170019",
        "name": "ANCA Screen No RF",
        "containers": "",
        "analytes": "ANCA Screen No RF Ref"
    },
    {
        "code": "500016",
        "name": "Anemia Panel Bundle",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chloride; Cholesterol; Clarity; Color; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Ferritin; Folic Acid (Folate); Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; H. Pylori Abs, IgG Qualitative; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Iron Total; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Mucous; Neutrophils Absolute; Neutrophils Percentage; Nitrite; pH, Urine; Platelets Count; Potassium; Protein, Urine; RDW; Red Blood Cell, Urine; Red Blood Cells; Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Total Iron Binding Capacity (TIBC); Total Protein; Transferrin; Triglycerides; Triiodothyronine (T3); Urobilinogen; Vitamin B12; Vitamin D, 25-Hydroxy; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "170020",
        "name": "Angiotensin Conv Enz",
        "containers": "",
        "analytes": "Angiotensin Conv Enz Ref"
    },
    {
        "code": "170061",
        "name": "Anti-CCP (Cyclic Citrullinated Peptide) Antibodies, IgG and IgA, ELISA",
        "containers": "",
        "analytes": "Anti-CCP (Cyclic Citrullinated Peptide) Antibodies, IgG and IgA, ELISA Ref"
    },
    {
        "code": "120030",
        "name": "Anti-Centromere Abs",
        "containers": "",
        "analytes": "Anti-Centromere Abs"
    },
    {
        "code": "120025",
        "name": "Anti-dsDNA Abs",
        "containers": "",
        "analytes": "Anti-dsDNA Abs"
    },
    {
        "code": "120035",
        "name": "Anti-Jo-1",
        "containers": "",
        "analytes": "Anti-Jo-1"
    },
    {
        "code": "120024",
        "name": "Anti-Nuclear Antibodies (ANA) Screen",
        "containers": "",
        "analytes": "Anti-Nuclear Antibodies (ANA) Screen"
    },
    {
        "code": "120029",
        "name": "Anti-SS-B",
        "containers": "",
        "analytes": "Anti-SS-B"
    },
    {
        "code": "170003",
        "name": "Antibody Screen",
        "containers": "",
        "analytes": "Antibody Screen Ref"
    },
    {
        "code": "120026",
        "name": "Antichromatin Abs",
        "containers": "",
        "analytes": "Antichromatin Abs"
    },
    {
        "code": "170364",
        "name": "Antichromatin Antibodies",
        "containers": "",
        "analytes": "Antichromatin Antibodies Ref"
    },
    {
        "code": "550001",
        "name": "Anticonvulsants",
        "containers": "",
        "analytes": "Gabapentin; Pregabalin"
    },
    {
        "code": "560001",
        "name": "Anticonvulsants - Oral",
        "containers": "",
        "analytes": "Gabapentin - Oral; Pregabalin - Oral"
    },
    {
        "code": "550012",
        "name": "Antidepressants",
        "containers": "",
        "analytes": "Amitriptyline; Citalopram; Desipramine; Desmethylcitalopram; Doxepin; Fluoxetine; Imipramine; Nortriptyline; Paroxetine; Sertraline"
    },
    {
        "code": "170302",
        "name": "Antimyeloperoxidase (MPO) Antibodies",
        "containers": "",
        "analytes": "Antimyeloperoxidase (MPO) Antibodies Ref"
    },
    {
        "code": "170231",
        "name": "Antipancreatic Islet Cells",
        "containers": "",
        "analytes": "Antipancreatic Islet Cells Ref"
    },
    {
        "code": "170330",
        "name": "Antiparietal Cell Antibody (APCA)",
        "containers": "",
        "analytes": "Antiparietal Cell Antibody (APCA) Ref"
    },
    {
        "code": "120034",
        "name": "Antiscleroderma - 70 Abs",
        "containers": "",
        "analytes": "Antiscleroderma - 70 Abs"
    },
    {
        "code": "120044",
        "name": "Antistreptolysin O (ASO) Qualitative",
        "containers": "",
        "analytes": "Antistreptolysin O (ASO) Qualitative"
    },
    {
        "code": "170378",
        "name": "Antithrombin (AT) Activity",
        "containers": "",
        "analytes": "Antithrombin (AT) Activity Ref"
    },
    {
        "code": "170021",
        "name": "Apolipoprotein A1",
        "containers": "",
        "analytes": "Apolipoprotein A1 Ref"
    },
    {
        "code": "170022",
        "name": "Apolipoprotein B",
        "containers": "",
        "analytes": "Apolipoprotein B Ref"
    },
    {
        "code": "170339",
        "name": "Apple, Allergen",
        "containers": "",
        "analytes": "Apple, Allergen Ref"
    },
    {
        "code": "500017",
        "name": "Arthritis Profile Bundle",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Anti-Nuclear Antibodies (ANA) Screen; Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; C-Reactive Protein, High Sensitivity (CRP-HS); Calcium; Carbon Dioxide; Chloride; Cholesterol; Clarity; Color; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Mucous; Neutrophils Absolute; Neutrophils Percentage; Nitrite; pH, Urine; Platelets Count; Potassium; Protein, Urine; RDW; Red Blood Cell, Urine; Red Blood Cells; Rheumatoid Factor; Sedimentation Rate, Westergren (ESR); Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Systemic Lupus Erythematosus (SLE); Thyroid Stimulating Hormone (TSH); Total Protein; Triglycerides; Uric Acid; Urobilinogen; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "100006",
        "name": "Aspartate Aminotransferase (AST)",
        "containers": "",
        "analytes": "Aspartate Aminotransferase (AST)"
    },
    {
        "code": "170024",
        "name": "Aspergillus Abs",
        "containers": "",
        "analytes": "Aspergillus Abs Ref"
    },
    {
        "code": "140148",
        "name": "Astrovirus",
        "containers": "",
        "analytes": "Astrovirus"
    },
    {
        "code": "140001",
        "name": "Atopobium Vaginae",
        "containers": "",
        "analytes": "Atopobium Vaginae"
    },
    {
        "code": "500010",
        "name": "AUC Super Panel",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chloride; Cholesterol; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Folic Acid (Folate); Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Hemoglobin A1c, Whole Blood; LDL Cholesterol(Calculated); Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Neutrophils Absolute; Neutrophils Percentage; Platelets Count; Potassium; RDW; Red Blood Cells; Sodium; Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Total Protein; Triglycerides; Triiodothyronine (T3); Vitamin B12; Vitamin D, 25-Hydroxy; VLDL Cholesterol; White Blood Cells"
    },
    {
        "code": "170277",
        "name": "Autoimmune Encephalopathy Profile",
        "containers": "",
        "analytes": "Autoimmune Encephalopathy Profile Ref"
    },
    {
        "code": "100054",
        "name": "B-Type Natriuretic Peptide (BNP)",
        "containers": "lavender_tube",
        "analytes": "B-Type Natriuretic Peptide (BNP)"
    },
    {
        "code": "540026",
        "name": "Bacteria - GI",
        "containers": "",
        "analytes": "Campylobpacter Sp; Clostridium Difficile Binary Toxin A/B; Clostridium Difficile Toxin A; Clostridium Difficile Toxin B; Enteroaggregative E. Coli (EAEC); Enteropathogenic E. Coli (EPEC); Enterotoxigenic E. Coli (ETEC); Plesiomonas Shigelloides; Salmonella Spp; Shiga Toxin Producing E. Coli (STEC); Shigella / Enteroinvasive E. Coli (EIEC); Vibrio Cholerae; Vibrio Parahaemolyticus; Yersinia Enterocolitica"
    },
    {
        "code": "540008",
        "name": "Bacterial Pneumonia by qPCR",
        "containers": "",
        "analytes": "Acinetobacter Calcoaceticus-Baumannii Complex; Chlamydophila Pneumoniae; Enterobacter Cloacae Complex; Escherichia Coli; Haemophilus Influenzae; Klebsiella Aerogenes; Klebsiella Oxytoca; Klebsiella pneumoniae; Legionella pneumophila; Moraxella Catarrhalis; Mycoplasma pneumoniae; Proteus spp.; Pseudomonas Aeruginosa; Serratia Marcescens; Staphylococcus Aureus; Streptococcus Agalactiae; Streptococcus pneumoniae; Streptococcus Pyogenes (Group A)"
    },
    {
        "code": "540001",
        "name": "Bacterial Vaginosis",
        "containers": "e_swab",
        "analytes": "Bacterial Vaginosis"
    },
    {
        "code": "140002",
        "name": "Bacterial Vaginosis",
        "containers": "e_swab",
        "analytes": "Bacterial Vaginosis"
    },
    {
        "code": "540010",
        "name": "Bacterial Vaginosis qPCR",
        "containers": "",
        "analytes": "Atopobium Vaginae; BVAB-2 / Megasphaera 1 / Megasphaera 2; Gardnerella Vaginalis"
    },
    {
        "code": "170340",
        "name": "Banana, Allergen",
        "containers": "",
        "analytes": "Banana, Allergen Ref"
    },
    {
        "code": "550004",
        "name": "Barbiturates",
        "containers": "",
        "analytes": "Amo-Pentobarbital; Butalbital; Phenobarbital; Secobarbital"
    },
    {
        "code": "150176",
        "name": "Barbiturates",
        "containers": "",
        "analytes": "Barbiturates - Cup"
    },
    {
        "code": "150163",
        "name": "Barbiturates - Actual",
        "containers": "",
        "analytes": "Barbiturates - EIA"
    },
    {
        "code": "150151",
        "name": "Barbiturates - EIA",
        "containers": "",
        "analytes": "Barbiturates - EIA"
    },
    {
        "code": "560003",
        "name": "Barbiturates - Oral",
        "containers": "",
        "analytes": "Butalbital - Oral; Phenobarbital - Oral"
    },
    {
        "code": "170026",
        "name": "Bartonella DNA PCR",
        "containers": "",
        "analytes": "Bartonella DNA PCR Ref"
    },
    {
        "code": "170025",
        "name": "Bartonella Quintana IgG w/RF",
        "containers": "",
        "analytes": "Bartonella Quintana IgG w/RF Ref"
    },
    {
        "code": "170027",
        "name": "Basement Membrane Ab",
        "containers": "",
        "analytes": "Basement Membrane Ab Ref"
    },
    {
        "code": "100058",
        "name": "Basic Liver Profile",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Aspartate Aminotransferase (AST); Bilirubin Direct (D-Bili); Bilirubin, Total (T-Bili); Gamma-Glutamyl Transferase (GGT); Lactic Acid Dehydrogenase (LDH); Total Protein"
    },
    {
        "code": "500004",
        "name": "Basic Metabolic Panel (BMP)",
        "containers": "tiger_and_gold_tube",
        "analytes": "Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chloride; Creatinine Serum; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Potassium; Sodium"
    },
    {
        "code": "550019",
        "name": "Bath Salts",
        "containers": "",
        "analytes": "MDPV"
    },
    {
        "code": "550005",
        "name": "Benzodiazepines",
        "containers": "",
        "analytes": "2-Hydroxyethylflurazepam; 7-Aminoclonazepam; A-Hydroxyalprazolam; A-Hydroxymidazolam; A-Hydroxytriazolam; Alprazolam; Clonazepam; Desalkylflurazepam; Diazepam; Flurazepam; Lorazepam; Nordiazepam; Oxazepam; Temazepam"
    },
    {
        "code": "150178",
        "name": "Benzodiazepines",
        "containers": "",
        "analytes": "Benzodiazepines - Cup"
    },
    {
        "code": "150164",
        "name": "Benzodiazepines - Actual",
        "containers": "",
        "analytes": "Benzodiazepines - EIA"
    },
    {
        "code": "150152",
        "name": "Benzodiazepines - EIA",
        "containers": "",
        "analytes": "Benzodiazepines - EIA"
    },
    {
        "code": "560004",
        "name": "Benzodiazepines - Oral",
        "containers": "",
        "analytes": "7-Aminoclonazepam - Oral; Alpha-Hydroxyalprazolam - Oral; Alprazolam - Oral; Clonazepam - Oral; Diazepam  - Oral; Lorazepam - Oral; Nordiazepam - Oral; Oxazepam - Oral; Temazepam - Oral"
    },
    {
        "code": "150019",
        "name": "Benzoylecgonine",
        "containers": "",
        "analytes": "Benzoylecgonine"
    },
    {
        "code": "160006",
        "name": "Benzoylecgonine - Oral",
        "containers": "",
        "analytes": "Benzoylecgonine - Oral"
    },
    {
        "code": "170294",
        "name": "Beryllium, Serum or Plasma",
        "containers": "",
        "analytes": "Beryllium, Serum or Plasma Ref"
    },
    {
        "code": "170028",
        "name": "Beta-2 Microglobulin",
        "containers": "",
        "analytes": "Beta-2 Microglobulin Ref"
    },
    {
        "code": "100007",
        "name": "Bilirubin Direct (D-Bili)",
        "containers": "",
        "analytes": "Bilirubin Direct (D-Bili)"
    },
    {
        "code": "100008",
        "name": "Bilirubin, Total (T-Bili)",
        "containers": "tiger_and_gold_tube",
        "analytes": "Bilirubin, Total (T-Bili)"
    },
    {
        "code": "170235",
        "name": "Biopsy, Tissue",
        "containers": "",
        "analytes": "Biopsy, Tissue Ref"
    },
    {
        "code": "170029",
        "name": "BKV PCR, Quant",
        "containers": "",
        "analytes": "BKV PCR, Quant Ref"
    },
    {
        "code": "170388",
        "name": "Bladder Cancer FISH Pathologist Review",
        "containers": "",
        "analytes": "Bladder Cancer FISH Pathologist Review Ref"
    },
    {
        "code": "170030",
        "name": "Blastomyces Ab, Id",
        "containers": "",
        "analytes": "Blastomyces Ab, Id Ref"
    },
    {
        "code": "170387",
        "name": "Blastomyces Antigen",
        "containers": "",
        "analytes": "Blastomyces Antigen Ref"
    },
    {
        "code": "170255",
        "name": "Blood Culture, Routine",
        "containers": "",
        "analytes": "Blood Culture, Routine Ref"
    },
    {
        "code": "100009",
        "name": "Blood Urea Nitrogen (BUN)",
        "containers": "tiger_and_gold_tube",
        "analytes": "Blood Urea Nitrogen (BUN)"
    },
    {
        "code": "170263",
        "name": "Body Fluid Culture, Sterile, Routine",
        "containers": "",
        "analytes": "Body Fluid Culture, Sterile, Routine Ref"
    },
    {
        "code": "140037",
        "name": "Bordetella Pertussis",
        "containers": "",
        "analytes": "Bordetella Pertussis"
    },
    {
        "code": "170320",
        "name": "Bordetella pertussis Antibodies, IgG, IgM",
        "containers": "",
        "analytes": "Bordetella Pt IgG Ref; Bordetella Pt IgM Ref"
    },
    {
        "code": "170350",
        "name": "Bordetella pertussis, Nasopharyngeal Culture",
        "containers": "",
        "analytes": "Bordetella pertussis, Nasopharyngeal Culture Ref"
    },
    {
        "code": "170214",
        "name": "Bordetella Pt Ab, IgA",
        "containers": "",
        "analytes": "Bordetella Pt Ab, IgA Ref"
    },
    {
        "code": "170031",
        "name": "Bordetella Pt IgG",
        "containers": "",
        "analytes": "Bordetella Pt IgG Ref"
    },
    {
        "code": "170316",
        "name": "Budgerigar Feather",
        "containers": "",
        "analytes": "Budgerigar Feather Ref"
    },
    {
        "code": "170032",
        "name": "Bullous Pemphigoid Bp180 Ab",
        "containers": "",
        "analytes": "Bullous Pemphigoid Bp180 Ab Ref"
    },
    {
        "code": "150177",
        "name": "Buprenorphine",
        "containers": "",
        "analytes": "Buprenorphine - Cup"
    },
    {
        "code": "150020",
        "name": "Buprenorphine",
        "containers": "",
        "analytes": "Buprenorphine"
    },
    {
        "code": "150153",
        "name": "Buprenorphine - EIA",
        "containers": "",
        "analytes": "Buprenorphine - EIA"
    },
    {
        "code": "160007",
        "name": "Buprenorphine - Oral",
        "containers": "",
        "analytes": "Buprenorphine - Oral"
    },
    {
        "code": "140159",
        "name": "Burkholderia spp.",
        "containers": "",
        "analytes": "Burkholderia spp"
    },
    {
        "code": "150023",
        "name": "Butalbital",
        "containers": "",
        "analytes": "Butalbital"
    },
    {
        "code": "160008",
        "name": "Butalbital - Oral",
        "containers": "",
        "analytes": "Butalbital - Oral"
    },
    {
        "code": "140072",
        "name": "BVAB-2 / Megasphaera 1 / Megasphaera 2",
        "containers": "",
        "analytes": "BVAB-2 / Megasphaera 1 / Megasphaera 2"
    },
    {
        "code": "170058",
        "name": "C-Peptide",
        "containers": "",
        "analytes": "C-Peptide Ref"
    },
    {
        "code": "100017",
        "name": "C-Reactive Protein",
        "containers": "",
        "analytes": "C-Reactive Protein"
    },
    {
        "code": "100019",
        "name": "C-Reactive Protein, High Sensitivity (CRP-HS)",
        "containers": "tiger_and_gold_tube",
        "analytes": "C-Reactive Protein, High Sensitivity (CRP-HS)"
    },
    {
        "code": "170053",
        "name": "C-Telopeptide",
        "containers": "",
        "analytes": "C-Telopeptide Ref"
    },
    {
        "code": "170034",
        "name": "CA 125",
        "containers": "tiger_and_gold_tube",
        "analytes": "CA 125 Ref"
    },
    {
        "code": "170035",
        "name": "CA 19-9 (Centocor)",
        "containers": "tiger_and_gold_tube",
        "analytes": "CA 19-9 (Centocor) Ref"
    },
    {
        "code": "170385",
        "name": "Calcitonin (Thyrocalcitonin)",
        "containers": "",
        "analytes": "Calcitonin (Thyrocalcitonin) Ref"
    },
    {
        "code": "170202",
        "name": "Calcitriol (1,25 di-OH Vitamin D)",
        "containers": "",
        "analytes": "Calcitriol (1,25 di-OH Vitamin D) Ref"
    },
    {
        "code": "100011",
        "name": "Calcium",
        "containers": "tiger_and_gold_tube",
        "analytes": "Calcium"
    },
    {
        "code": "170036",
        "name": "Calcium 24Hr Ur",
        "containers": "",
        "analytes": "Calcium 24Hr Ur Ref"
    },
    {
        "code": "170037",
        "name": "Calcium w/o Creat 24H Ur",
        "containers": "",
        "analytes": "Calcium w/o Creat 24H Ur Ref"
    },
    {
        "code": "170038",
        "name": "Calcium, Ionized",
        "containers": "",
        "analytes": "Calcium, Ionized Ref"
    },
    {
        "code": "170344",
        "name": "Calcium, Random Urine",
        "containers": "",
        "analytes": "Calcium, Random Urine Ref"
    },
    {
        "code": "170381",
        "name": "Calcium/Creatinine Ratio",
        "containers": "",
        "analytes": "Calcium/Creatinine Ratio Ref"
    },
    {
        "code": "170286",
        "name": "Calculi, with Photograph",
        "containers": "",
        "analytes": "Calculi, with Photograph Ref"
    },
    {
        "code": "170039",
        "name": "Calprotectin, Stool",
        "containers": "",
        "analytes": "Calprotectin, Stool Ref"
    },
    {
        "code": "140134",
        "name": "Campylobpacter Sp",
        "containers": "",
        "analytes": "Campylobpacter Sp"
    },
    {
        "code": "170258",
        "name": "Cancer Antigen (CA) 15-3",
        "containers": "",
        "analytes": "Cancer Antigen (CA) 15-3 Ref"
    },
    {
        "code": "170270",
        "name": "Cancer Antigen (CA) 27.29",
        "containers": "tiger_and_gold_tube",
        "analytes": "Cancer Antigen (CA) 27.29 Ref"
    },
    {
        "code": "170040",
        "name": "Candida Ab, Immunodiffusion",
        "containers": "",
        "analytes": "Candida Ab, Immunodiffusion Ref"
    },
    {
        "code": "140003",
        "name": "Candida Albicans",
        "containers": "",
        "analytes": "Candida Albicans"
    },
    {
        "code": "170033",
        "name": "Candida Albicans Ab",
        "containers": "",
        "analytes": "Candida Albicans Ab Ref"
    },
    {
        "code": "140082",
        "name": "Candida Auris",
        "containers": "",
        "analytes": "Candida Auris"
    },
    {
        "code": "140004",
        "name": "Candida Glabrata",
        "containers": "",
        "analytes": "Candida Glabrata"
    },
    {
        "code": "140005",
        "name": "Candida Krusei",
        "containers": "",
        "analytes": "Candida Krusei"
    },
    {
        "code": "540002",
        "name": "Candida Panel",
        "containers": "",
        "analytes": "Candida Glabrata; Candida Species (Tropicalis, Parapsilosis & Dubliniensis)"
    },
    {
        "code": "140006",
        "name": "Candida Parapsilosis",
        "containers": "",
        "analytes": "Candida Parapsilosis"
    },
    {
        "code": "140030",
        "name": "Candida Species (Tropicalis, Parapsilosis & Dubliniensis)",
        "containers": "",
        "analytes": "Candida Species (Tropicalis, Parapsilosis & Dubliniensis)"
    },
    {
        "code": "140007",
        "name": "Candida Tropicalis",
        "containers": "",
        "analytes": "Candida Tropicalis"
    },
    {
        "code": "540009",
        "name": "Candida Vaginitis qPCR",
        "containers": "e_swab",
        "analytes": "Candida Albicans; Candida Glabrata; Candida Species (Tropicalis, Parapsilosis & Dubliniensis)"
    },
    {
        "code": "170041",
        "name": "Carbamazepine, Total",
        "containers": "",
        "analytes": "Carbamazepine, Total Ref"
    },
    {
        "code": "100013",
        "name": "Carbon Dioxide",
        "containers": "",
        "analytes": "Carbon Dioxide"
    },
    {
        "code": "120001",
        "name": "Carcinoembryonic Ag (CEA)",
        "containers": "",
        "analytes": "Carcinoembryonic Ag (CEA)"
    },
    {
        "code": "500023",
        "name": "Cardiac Risk Profile",
        "containers": "",
        "analytes": "C-Reactive Protein, High Sensitivity (CRP-HS); Cholesterol; HDL Cholesterol; HDL/Cholesterol Ratio; Homocysteine; LDL Cholesterol(Calculated); Triglycerides; VLDL Cholesterol"
    },
    {
        "code": "170043",
        "name": "Cardiolipin Ab IgA, IgG, IgM",
        "containers": "",
        "analytes": "Cardiolipin Ab IgA, IgG, IgM Ref"
    },
    {
        "code": "150024",
        "name": "Carisoprodol",
        "containers": "",
        "analytes": "Carisoprodol"
    },
    {
        "code": "160009",
        "name": "Carisoprodol - Oral",
        "containers": "",
        "analytes": "Carisoprodol - Oral"
    },
    {
        "code": "170276",
        "name": "Carnitine, Total and Free",
        "containers": "",
        "analytes": "Carnitine, Free Ref; Carnitine, Total Ref; Esterified/Free Ref"
    },
    {
        "code": "170369",
        "name": "Catecholamines, Fractionated Urine 24h",
        "containers": "",
        "analytes": "Dopamine Urine 24h Ref; Dopamine Urine Ref; Epinephrine Urine 24h Ref; Epinephrine Urine Ref; Norepinephrine Urine 24h Ref; Norepinephrine Urine Ref"
    },
    {
        "code": "170044",
        "name": "Catecholamines, Plasma",
        "containers": "",
        "analytes": "Catecholamines, Plasma Ref"
    },
    {
        "code": "510001",
        "name": "CBC with Diff",
        "containers": "lavender_tube",
        "analytes": "Basophils Absolute; Basophils Percentage; Eosinophils Absolute; Eosinophils Percentage; Hematocrit; Hemoglobin; Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Neutrophils Absolute; Neutrophils Percentage; Platelets Count; RDW; Red Blood Cells; White Blood Cells"
    },
    {
        "code": "510002",
        "name": "CBC without Diff",
        "containers": "lavender_tube",
        "analytes": "Hematocrit; Hemoglobin; MCH; MCHC; MCV; Platelets Count; RDW; Red Blood Cells; White Blood Cells"
    },
    {
        "code": "170290",
        "name": "Celiac Disease Panel",
        "containers": "",
        "analytes": "Celiac Disease Panel Ref"
    },
    {
        "code": "170262",
        "name": "Cell Count, Synovial Fluid With Crystals",
        "containers": "",
        "analytes": "Cell Count, Synovial Fluid With Crystals Ref"
    },
    {
        "code": "170045",
        "name": "Ceruloplasmin",
        "containers": "",
        "analytes": "Ceruloplasmin Ref"
    },
    {
        "code": "500024",
        "name": "Chem 18",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bilirubin, Total (T-Bili); Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chloride; Cholesterol; CPK Total; Creatinine Serum; Gamma-Glutamyl Transferase (GGT); Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; HDL Cholesterol; HDL/Cholesterol Ratio; Iron Total; LDL Cholesterol(Calculated); Potassium; Sodium; Total Protein; Triglycerides; VLDL Cholesterol"
    },
    {
        "code": "170338",
        "name": "Chick Pea, Allergen",
        "containers": "",
        "analytes": "Chick Pea, Allergen Ref"
    },
    {
        "code": "140009",
        "name": "Chlamydia Trachomatis",
        "containers": "",
        "analytes": "Chlamydia Trachomatis"
    },
    {
        "code": "140202",
        "name": "Chlamydia trachomatis - 3 Source",
        "containers": "",
        "analytes": "Chlamydia trachomatis - Oral; Chlamydia trachomatis - Rectal; Chlamydia trachomatis - Urine"
    },
    {
        "code": "170301",
        "name": "Chlamydia/Gonococcus, Pharyngeal Swab, NAA",
        "containers": "",
        "analytes": "Chlamydia/Gonococcus, Pharyngeal Swab, NAA Ref"
    },
    {
        "code": "170308",
        "name": "Chlamydia/Gonococcus, Rectal Swab",
        "containers": "",
        "analytes": "Chlamydia/Gonococcus, Rectal Swab Ref"
    },
    {
        "code": "140038",
        "name": "Chlamydophila Pneumoniae",
        "containers": "",
        "analytes": "Chlamydophila Pneumoniae"
    },
    {
        "code": "100014",
        "name": "Chloride",
        "containers": "",
        "analytes": "Chloride"
    },
    {
        "code": "170047",
        "name": "Chloride w/o Creat 24H Ur",
        "containers": "",
        "analytes": "Chloride w/o Creat 24H Ur Ref"
    },
    {
        "code": "170375",
        "name": "Chloride, Urine",
        "containers": "",
        "analytes": "Chloride, Urine Ref"
    },
    {
        "code": "100015",
        "name": "Cholesterol",
        "containers": "tiger_and_gold_tube",
        "analytes": "Cholesterol"
    },
    {
        "code": "170293",
        "name": "Chromium, Plasma",
        "containers": "",
        "analytes": "Chromium, Plasma Ref"
    },
    {
        "code": "170335",
        "name": "Chromosome Analysis With Reflex to SNP Microarray−Pediatric",
        "containers": "",
        "analytes": "Chromosome Analysis With Reflex to SNP Microarray−Pediatric Ref"
    },
    {
        "code": "170396",
        "name": "Chromosome Analysis, Tissue Biopsies",
        "containers": "",
        "analytes": "Chromosome Analysis, Tissue Biopsies Ref"
    },
    {
        "code": "170271",
        "name": "Chronic Lymphocytic Leukemia (CLL) Profile, FISH",
        "containers": "",
        "analytes": "Chronic Lymphocytic Leukemia (CLL) Profile, FISH Ref"
    },
    {
        "code": "150025",
        "name": "Citalopram",
        "containers": "",
        "analytes": "Citalopram"
    },
    {
        "code": "170048",
        "name": "Citric Acid w/o Creat 24H Ur",
        "containers": "",
        "analytes": "Citric Acid w/o Creat 24H Ur Ref"
    },
    {
        "code": "140160",
        "name": "Citrobacter freundii",
        "containers": "",
        "analytes": "Citrobacter Freundii"
    },
    {
        "code": "140083",
        "name": "Citrobacter Freundii",
        "containers": "",
        "analytes": "Citrobacter Freundii"
    },
    {
        "code": "170049",
        "name": "CK-MB (CK-2)",
        "containers": "tiger_and_gold_tube",
        "analytes": "CK-MB (CK-2) Ref"
    },
    {
        "code": "170315",
        "name": "Clobazam",
        "containers": "",
        "analytes": "Clobazam Ref"
    },
    {
        "code": "150026",
        "name": "Clonazepam",
        "containers": "",
        "analytes": "Clonazepam"
    },
    {
        "code": "160010",
        "name": "Clonazepam - Oral",
        "containers": "",
        "analytes": "Clonazepam - Oral"
    },
    {
        "code": "170356",
        "name": "Clonazepam, Serum",
        "containers": "",
        "analytes": "Clonazepam, Serum Ref"
    },
    {
        "code": "140146",
        "name": "Clostridium Difficile Binary Toxin A/B",
        "containers": "",
        "analytes": "Clostridium Difficile Binary Toxin A/B"
    },
    {
        "code": "140145",
        "name": "Clostridium Difficile Toxin A",
        "containers": "",
        "analytes": "Clostridium Difficile Toxin A"
    },
    {
        "code": "140144",
        "name": "Clostridium Difficile Toxin B",
        "containers": "",
        "analytes": "Clostridium Difficile Toxin B"
    },
    {
        "code": "130010",
        "name": "Clostridium Difficile Toxins (A & B)",
        "containers": "",
        "analytes": "Clostridium Difficile Toxins (A & B)"
    },
    {
        "code": "140161",
        "name": "Clostridium novyi",
        "containers": "",
        "analytes": "Clostridium novyi"
    },
    {
        "code": "140162",
        "name": "Clostridium perfringens",
        "containers": "",
        "analytes": "Clostridium perfringens"
    },
    {
        "code": "140163",
        "name": "Clostridium septicum",
        "containers": "",
        "analytes": "Clostridium septicum"
    },
    {
        "code": "170227",
        "name": "Clozapine, Serum or Plasma",
        "containers": "",
        "analytes": "Clozapine, Serum or Plasma ref"
    },
    {
        "code": "170050",
        "name": "CMV DNA Qn, PCR",
        "containers": "",
        "analytes": "CMV DNA Qn, PCR Ref"
    },
    {
        "code": "170051",
        "name": "CMV IgM Ab",
        "containers": "",
        "analytes": "CMV IgM Ab Ref"
    },
    {
        "code": "550006",
        "name": "Cocaine",
        "containers": "",
        "analytes": "Benzoylecgonine"
    },
    {
        "code": "150179",
        "name": "Cocaine",
        "containers": "",
        "analytes": "Cocaine - Cup"
    },
    {
        "code": "150165",
        "name": "Cocaine - Actual",
        "containers": "",
        "analytes": "Cocaine - EIA"
    },
    {
        "code": "150154",
        "name": "Cocaine - EIA",
        "containers": "",
        "analytes": "Cocaine - EIA"
    },
    {
        "code": "560005",
        "name": "Cocaine - Oral",
        "containers": "",
        "analytes": "Benzoylecgonine - Oral"
    },
    {
        "code": "170052",
        "name": "Coccidio Ab, Id",
        "containers": "",
        "analytes": "Coccidio Ab, Id Ref"
    },
    {
        "code": "150028",
        "name": "Codeine",
        "containers": "",
        "analytes": "Codeine"
    },
    {
        "code": "160011",
        "name": "Codeine - Oral",
        "containers": "",
        "analytes": "Codeine - Oral"
    },
    {
        "code": "540006",
        "name": "Common Respiratory Viral/Bacterial qPCR Panel",
        "containers": "",
        "analytes": "Adenovirus; Bordetella Pertussis; Chlamydophila Pneumoniae; Haemophilus Influenzae; Human Enterovirus/Human Rhinovirus; Human Metapneumoviruses; Influenza A; Influenza B; Legionella pneumophila; Mycoplasma pneumoniae; Respiratory Syncytial Virus A/B; SARS-CoV-2 by PCR; Streptococcus pneumoniae; Streptococcus Pyogenes (Group A)"
    },
    {
        "code": "170054",
        "name": "Complement C3",
        "containers": "",
        "analytes": "Complement C3 Ref"
    },
    {
        "code": "170055",
        "name": "Complement C4",
        "containers": "",
        "analytes": "Complement C4 Ref"
    },
    {
        "code": "170056",
        "name": "Complement, (CH50)",
        "containers": "",
        "analytes": "Complement, (CH50) Ref"
    },
    {
        "code": "500005",
        "name": "Comprehensive Metabolic Panel (CMP)",
        "containers": "tiger_and_gold_tube",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bilirubin, Total (T-Bili); Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chloride; Creatinine Serum; Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Potassium; Sodium; Total Protein"
    },
    {
        "code": "540037",
        "name": "Conjunctivitis Panel",
        "containers": "",
        "analytes": "Acinetobacter Baumannii; Adenovirus; Candida Albicans; Candida Auris; Candida Glabrata; Candida Krusei; Candida Parapsilosis; Candida Tropicalis; Chlamydia Trachomatis; Citrobacter Freundii; Enterobacter Cloacae Complex; Enterococcus Faecalis; Enterococcus Faecium; Epstein-Barr Virus; Escherichia Coli; Haemophilus Influenzae; Human Enterovirus/Human Rhinovirus; Human Metapneumoviruses; Klebsiella Aerogenes; Klebsiella Oxytoca; Klebsiella pneumoniae; Morganella Morganii; Neisseria Gonorrhoeae; Parainfluenza 1; Parainfluenza 2; Parainfluenza 3; Parainfluenza 4; Proteus Mirabilis; Proteus Vulgaris; Pseudomonas Aeruginosa; SARS-CoV-2 by PCR; Staphylococcus Aureus; Streptococcus Agalactiae; Streptococcus pneumoniae; Streptococcus Pyogenes (Group A)"
    },
    {
        "code": "540039",
        "name": "Conjunctivitis Panel w/ Antibiotic Resistance",
        "containers": "",
        "analytes": "Acinetobacter Baumannii; Adenovirus; Candida Albicans; Candida Auris; Candida Glabrata; Candida Krusei; Candida Parapsilosis; Candida Tropicalis; Chlamydia Trachomatis; Citrobacter Freundii; CTX-M ESBL - Beta Lactamase; Enterobacter Cloacae Complex; Enterococcus Faecalis; Enterococcus Faecium; Epstein-Barr Virus; Escherichia Coli; Fluoroquinolone (Qnr); Haemophilus Influenzae; Human Enterovirus/Human Rhinovirus; Human Metapneumoviruses; IMP - Carbapenem; Klebsiella Aerogenes; Klebsiella Oxytoca; Klebsiella pneumoniae; KPC - Carbapenem; Methicillin (mecA+mecC); Morganella Morganii; NDM - Carbapenem; Neisseria Gonorrhoeae; OXA - 48 - Carbapenem; Parainfluenza 1; Parainfluenza 2; Parainfluenza 3; Parainfluenza 4; Proteus Mirabilis; Proteus Vulgaris; Pseudomonas Aeruginosa; SARS-CoV-2 by PCR; Staphylococcus Aureus; Streptococcus Agalactiae; Streptococcus pneumoniae; Streptococcus Pyogenes (Group A); Sulfonamide (Sul); Trimethoprim (dfrA); Van A - Vancomycin; Van B - Vancomycin; VIM - Carbapenem"
    },
    {
        "code": "170366",
        "name": "Coombs Direct",
        "containers": "",
        "analytes": "Anti-Complement Ref; Anti-IgG Ref; Coombs Direct Ref"
    },
    {
        "code": "170057",
        "name": "Copper, Serum",
        "containers": "",
        "analytes": "Copper, Serum Ref"
    },
    {
        "code": "120002",
        "name": "Cortisol",
        "containers": "",
        "analytes": "Cortisol"
    },
    {
        "code": "170362",
        "name": "Cortisol AM",
        "containers": "",
        "analytes": "Cortisol AM Ref"
    },
    {
        "code": "170319",
        "name": "Cortisone, LC/MS-MS",
        "containers": "",
        "analytes": "Cortisone, LC/MS-MS Ref"
    },
    {
        "code": "140084",
        "name": "Corynebacterium Urealyticum ",
        "containers": "",
        "analytes": "Corynebacterium Urealyticum"
    },
    {
        "code": "150029",
        "name": "Cotinine",
        "containers": "",
        "analytes": "Cotinine"
    },
    {
        "code": "140010",
        "name": "COVID-19 PCR (Nasal)",
        "containers": "",
        "analytes": "COVID-19 PCR"
    },
    {
        "code": "140011",
        "name": "COVID-19 PCR (Saliva)",
        "containers": "",
        "analytes": "COVID-19 PCR"
    },
    {
        "code": "140012",
        "name": "COVID-19 Rapid Antigen",
        "containers": "",
        "analytes": "COVID-19 Rapid Antigen"
    },
    {
        "code": "540003",
        "name": "COVID-19, Influenza A/B, RSV, NAA",
        "containers": "",
        "analytes": "Influenza A; Influenza B; Respiratory Syncytial Virus (RSV), NAA; SARS-CoV-2 by PCR"
    },
    {
        "code": "540004",
        "name": "COVID-19, Influenza A/B, RSV, NAA (Saliva)",
        "containers": "",
        "analytes": "COVID-19 PCR; Influenza A, Influenza B, NAA; Respiratory Syncytial Virus (RSV), NAA"
    },
    {
        "code": "170280",
        "name": "Coxsackie A Virus Antibody Profile, IgG, IgM",
        "containers": "",
        "analytes": "Coxsackie A Virus Antibody Profile, IgG, IgM Ref"
    },
    {
        "code": "170279",
        "name": "Coxsackie Virus Group B Antibodies",
        "containers": "",
        "analytes": "Coxsackie Virus Group B Antibodies Ref"
    },
    {
        "code": "170246",
        "name": "Creatine and GAA Plasma",
        "containers": "",
        "analytes": "Creatine and GAA Plasma Ref"
    },
    {
        "code": "100016",
        "name": "Creatine Kinase, Total",
        "containers": "",
        "analytes": "CPK Total"
    },
    {
        "code": "150169",
        "name": "Creatinine",
        "containers": "tiger_and_gold_tube",
        "analytes": "Creatinine"
    },
    {
        "code": "170059",
        "name": "Creatinine 24H Ur",
        "containers": "",
        "analytes": "Creatinine 24H Ur Ref"
    },
    {
        "code": "100022",
        "name": "Creatinine Serum",
        "containers": "tiger_and_gold_tube",
        "analytes": "Creatinine Serum"
    },
    {
        "code": "100021",
        "name": "Creatinine, Ur",
        "containers": "",
        "analytes": "Creatinine Ur"
    },
    {
        "code": "140154",
        "name": "Cryptosporidium Spp.",
        "containers": "",
        "analytes": "Cryptosporidium Spp."
    },
    {
        "code": "130002",
        "name": "Culture, Miscellaneous",
        "containers": "",
        "analytes": "Culture, Miscellaneous"
    },
    {
        "code": "130004",
        "name": "Culture, Throat",
        "containers": "aerobic_culture_swab",
        "analytes": "Culture, Throat"
    },
    {
        "code": "130015",
        "name": "Culture, Urine",
        "containers": "urine_culture",
        "analytes": "Culture, Urine"
    },
    {
        "code": "130005",
        "name": "Culture, Urine",
        "containers": "urine_culture",
        "analytes": "Culture, Urine"
    },
    {
        "code": "130006",
        "name": "Culture, Vaginal",
        "containers": "",
        "analytes": "Culture, Vaginal"
    },
    {
        "code": "130007",
        "name": "Culture, Wound",
        "containers": "aerobic_culture_swab",
        "analytes": "Culture, Wound"
    },
    {
        "code": "130008",
        "name": "Culture, Wound (Panel)",
        "containers": "aerobic_culture_swab",
        "analytes": "Culture, Wound; Gram Stain"
    },
    {
        "code": "170060",
        "name": "Culture,Sputum/Lower Resp",
        "containers": "",
        "analytes": "Culture,Sputum/Lower Resp Ref"
    },
    {
        "code": "140164",
        "name": "Cutibacterium spp.",
        "containers": "",
        "analytes": "Cutibacterium spp"
    },
    {
        "code": "150030",
        "name": "Cyclobenzaprine",
        "containers": "",
        "analytes": "Cyclobenzaprine"
    },
    {
        "code": "160012",
        "name": "Cyclobenzaprine - Oral",
        "containers": "",
        "analytes": "Cyclobenzaprine - Oral"
    },
    {
        "code": "140155",
        "name": "Cyclospora Cayetanensis",
        "containers": "",
        "analytes": "Cyclospora Cayetanensis"
    },
    {
        "code": "170062",
        "name": "Cyclosporine LCMS",
        "containers": "",
        "analytes": "Cyclosporine LCMS Ref"
    },
    {
        "code": "170295",
        "name": "Cystatin C",
        "containers": "",
        "analytes": "Cystatin C Ref"
    },
    {
        "code": "170063",
        "name": "Cyto, Non-Gyn, Liq Prep 1",
        "containers": "",
        "analytes": "Cyto, Non-Gyn, Liq Prep 1 Ref"
    },
    {
        "code": "170250",
        "name": "Cytokine Panel 13",
        "containers": "",
        "analytes": "Cytokine Panel 13 Ref"
    },
    {
        "code": "170064",
        "name": "Cytyc Pap",
        "containers": "",
        "analytes": "Cytyc Pap Ref"
    },
    {
        "code": "170065",
        "name": "Cytyc Pap & Rvw",
        "containers": "",
        "analytes": "Cytyc Pap & Rvw Ref"
    },
    {
        "code": "170066",
        "name": "D-Dimer",
        "containers": "light_blue_tube",
        "analytes": "D-Dimer Ref"
    },
    {
        "code": "120038",
        "name": "Dehydroepiandrosterone Sulfate (DHEA-S)",
        "containers": "",
        "analytes": "Dehydroepiandrosterone Sulfate (DHEA-S)"
    },
    {
        "code": "150032",
        "name": "Desalkylflurazepam",
        "containers": "",
        "analytes": "Desalkylflurazepam"
    },
    {
        "code": "150033",
        "name": "Desipramine",
        "containers": "",
        "analytes": "Desipramine"
    },
    {
        "code": "150034",
        "name": "Desmethylcitalopram",
        "containers": "",
        "analytes": "Desmethylcitalopram"
    },
    {
        "code": "150038",
        "name": "Desmethyltapentadol",
        "containers": "",
        "analytes": "Desmethyltapentadol"
    },
    {
        "code": "500021",
        "name": "Diabetes Profile",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; C-Reactive Protein, High Sensitivity (CRP-HS); Calcium; Carbon Dioxide; Chloride; Cholesterol; Clarity; Color; Cortisol; Creatinine Serum; Creatinine Ur; Eosinophils Absolute; Eosinophils Percentage; Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Hemoglobin A1c, Whole Blood; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Microalbumin Ur; Monocytes Absolute; Monocytes Percentage; Mucous; Neutrophils Absolute; Neutrophils Percentage; Nitrite; pH, Urine; Platelets Count; Potassium; Protein, Urine; RDW; Red Blood Cell, Urine; Red Blood Cells; Sedimentation Rate, Westergren (ESR); Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Total Protein; Triglycerides; Triiodothyronine (T3); Urobilinogen; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "150039",
        "name": "Diazepam",
        "containers": "",
        "analytes": "Diazepam"
    },
    {
        "code": "160013",
        "name": "Diazepam - Oral",
        "containers": "",
        "analytes": "Diazepam  - Oral"
    },
    {
        "code": "170067",
        "name": "Digoxin",
        "containers": "",
        "analytes": "Digoxin Ref"
    },
    {
        "code": "150040",
        "name": "Dihydrocodeine",
        "containers": "",
        "analytes": "Dihydrocodeine"
    },
    {
        "code": "170068",
        "name": "Dihydrotestosterone",
        "containers": "",
        "analytes": "Dihydrotestosterone Ref"
    },
    {
        "code": "170069",
        "name": "Diphtheria Antitoxoid",
        "containers": "",
        "analytes": "Diphtheria Antitoxoid Ref"
    },
    {
        "code": "150041",
        "name": "Doxepin",
        "containers": "",
        "analytes": "Doxepin"
    },
    {
        "code": "170209",
        "name": "EBV Ab VCA IgM",
        "containers": "",
        "analytes": "EBV Ab VCA IgM Ref"
    },
    {
        "code": "170208",
        "name": "EBV Early AG D IgG",
        "containers": "",
        "analytes": "EBV Early AG D IgG Ref"
    },
    {
        "code": "550017",
        "name": "Ecstacy",
        "containers": "",
        "analytes": "MDA; MDMA"
    },
    {
        "code": "560008",
        "name": "Ecstasy - Oral",
        "containers": "",
        "analytes": "MDA - Oral; MDMA - Oral"
    },
    {
        "code": "150043",
        "name": "EDDP",
        "containers": "",
        "analytes": "EDDP"
    },
    {
        "code": "160014",
        "name": "EDDP - Oral",
        "containers": "",
        "analytes": "EDDP - Oral"
    },
    {
        "code": "170071",
        "name": "Egg Component Panel",
        "containers": "",
        "analytes": "Egg Component Panel Ref"
    },
    {
        "code": "500027",
        "name": "Electrolyte Panel",
        "containers": "",
        "analytes": "Carbon Dioxide; Chloride; Potassium; Sodium"
    },
    {
        "code": "170353",
        "name": "Endomysial Antibody, IgA",
        "containers": "",
        "analytes": "Endomysial Antibody, IgA Ref"
    },
    {
        "code": "140153",
        "name": "Entamoeba Histolytica",
        "containers": "",
        "analytes": "Entamoeba Histolytica"
    },
    {
        "code": "140140",
        "name": "Enteroaggregative E. Coli (EAEC)",
        "containers": "",
        "analytes": "Enteroaggregative E. Coli (EAEC)"
    },
    {
        "code": "140165",
        "name": "Enterobacter cloacae Complex",
        "containers": "",
        "analytes": "Enterobacter Cloacae Complex"
    },
    {
        "code": "140061",
        "name": "Enterobacter Cloacae Complex",
        "containers": "",
        "analytes": "Enterobacter Cloacae Complex"
    },
    {
        "code": "140166",
        "name": "Enterococcus faecalis",
        "containers": "",
        "analytes": "Enterococcus Faecalis"
    },
    {
        "code": "140085",
        "name": "Enterococcus Faecalis",
        "containers": "",
        "analytes": "Enterococcus Faecalis"
    },
    {
        "code": "140167",
        "name": "Enterococcus faecium",
        "containers": "",
        "analytes": "Enterococcus Faecium"
    },
    {
        "code": "140086",
        "name": "Enterococcus Faecium",
        "containers": "",
        "analytes": "Enterococcus Faecium"
    },
    {
        "code": "140142",
        "name": "Enteropathogenic E. Coli (EPEC)",
        "containers": "",
        "analytes": "Enteropathogenic E. Coli (EPEC)"
    },
    {
        "code": "140143",
        "name": "Enterotoxigenic E. Coli (ETEC)",
        "containers": "",
        "analytes": "Enterotoxigenic E. Coli (ETEC)"
    },
    {
        "code": "170278",
        "name": "Enterovirus PCR, P",
        "containers": "",
        "analytes": "Enterovirus PCR, P Ref"
    },
    {
        "code": "540029",
        "name": "Epstein-Barr Virus",
        "containers": "",
        "analytes": "Epstein-Barr Virus"
    },
    {
        "code": "170072",
        "name": "Epstein-Barr Virus (EBV) Ab Panel",
        "containers": "",
        "analytes": "Epstein-Barr Virus (EBV) Ab Panel Ref"
    },
    {
        "code": "170215",
        "name": "Epstein-Barr Virus (EBV) Ab Profile",
        "containers": "",
        "analytes": "Epstein-Barr Virus (EBV) Ab Profile Ref"
    },
    {
        "code": "170073",
        "name": "Epstein-Barr Virus (EBV) Early Ag D Abs IgG",
        "containers": "",
        "analytes": "Epstein-Barr Virus (EBV) Early Ag D Abs IgG Ref"
    },
    {
        "code": "170239",
        "name": "Epstein-Barr Virus (EBV), Quant, PCR",
        "containers": "",
        "analytes": "Epstein-Barr Virus (EBV), Quant, PCR Ref"
    },
    {
        "code": "170225",
        "name": "Erythropoietin (EPO), Serum",
        "containers": "",
        "analytes": "Erythropoietin (EPO), Serum Ref"
    },
    {
        "code": "140168",
        "name": "Escherichia coli",
        "containers": "",
        "analytes": "Escherichia Coli"
    },
    {
        "code": "140062",
        "name": "Escherichia Coli",
        "containers": "",
        "analytes": "Escherichia Coli"
    },
    {
        "code": "120003",
        "name": "Estradiol",
        "containers": "",
        "analytes": "Estradiol"
    },
    {
        "code": "170074",
        "name": "Estradiol, Free",
        "containers": "",
        "analytes": "Estradiol, Free Ref"
    },
    {
        "code": "170334",
        "name": "Estriol (E3)",
        "containers": "",
        "analytes": "Estriol (E3) Ref"
    },
    {
        "code": "170075",
        "name": "Estrogen, Total",
        "containers": "",
        "analytes": "Estrogen, Total Ref"
    },
    {
        "code": "170380",
        "name": "Ethanol, Whole Blood",
        "containers": "",
        "analytes": "Ethanol, Whole Blood Ref"
    },
    {
        "code": "150046",
        "name": "Ethyl Sulfate (ETS)",
        "containers": "",
        "analytes": "Ethyl Sulfate (ETS)"
    },
    {
        "code": "150045",
        "name": "Ethyl-B-D-Glucuronide (ETG)",
        "containers": "",
        "analytes": "Ethyl-B-D-Glucuronide (ETG)"
    },
    {
        "code": "170216",
        "name": "Factor II (Prothrombin), DNA Analysis",
        "containers": "",
        "analytes": "Factor II (Prothrombin), DNA Analysis Ref"
    },
    {
        "code": "170379",
        "name": "Factor V Leiden With Reflex to R2",
        "containers": "",
        "analytes": "Factor V Leiden With Reflex to R2 Ref"
    },
    {
        "code": "170382",
        "name": "Factor XIII Activity",
        "containers": "",
        "analytes": "Factor XIII Activity Ref"
    },
    {
        "code": "500026",
        "name": "Female Endocrine Profile",
        "containers": "",
        "analytes": "Cortisol; Estradiol; Follicle-Stimulating Hormone (FSH); Luteinizing Hormone (LH); Progesterone; Prolactin; Testosterone, Total; Thyroid Stimulating Hormone (TSH); Thyroxine (T4)"
    },
    {
        "code": "150047",
        "name": "Fentanyl",
        "containers": "",
        "analytes": "Fentanyl"
    },
    {
        "code": "160015",
        "name": "Fentanyl - Oral",
        "containers": "",
        "analytes": "Fentanyl - Oral"
    },
    {
        "code": "550020",
        "name": "Fentanyls",
        "containers": "",
        "analytes": "Fentanyl; Norfentanyl"
    },
    {
        "code": "560009",
        "name": "Fentanyls - Oral",
        "containers": "",
        "analytes": "Fentanyl - Oral; Norfentanyl - Oral"
    },
    {
        "code": "120004",
        "name": "Ferritin",
        "containers": "tiger_and_gold_tube",
        "analytes": "Ferritin"
    },
    {
        "code": "150048",
        "name": "Fluoxetine",
        "containers": "",
        "analytes": "Fluoxetine"
    },
    {
        "code": "150049",
        "name": "Flurazepam",
        "containers": "",
        "analytes": "Flurazepam"
    },
    {
        "code": "170076",
        "name": "Folate, RBC",
        "containers": "",
        "analytes": "Folate, RBC Ref"
    },
    {
        "code": "120005",
        "name": "Folic Acid (Folate)",
        "containers": "",
        "analytes": "Folic Acid (Folate)"
    },
    {
        "code": "120006",
        "name": "Follicle-Stimulating Hormone (FSH)",
        "containers": "tiger_and_gold_tube",
        "analytes": "Follicle-Stimulating Hormone (FSH)"
    },
    {
        "code": "170077",
        "name": "Food Allergy w/RF",
        "containers": "",
        "analytes": "Food Allergy w/RF Ref"
    },
    {
        "code": "170252",
        "name": "Fructosamine",
        "containers": "",
        "analytes": "Fructosamine Ref"
    },
    {
        "code": "170078",
        "name": "FTA-Abs",
        "containers": "",
        "analytes": "FTA-Abs Ref"
    },
    {
        "code": "170371",
        "name": "Fungus Culture With Stain",
        "containers": "",
        "analytes": "Fungus Culture With Stain Ref"
    },
    {
        "code": "150050",
        "name": "Gabapentin",
        "containers": "",
        "analytes": "Gabapentin"
    },
    {
        "code": "160016",
        "name": "Gabapentin - Oral",
        "containers": "",
        "analytes": "Gabapentin - Oral"
    },
    {
        "code": "170080",
        "name": "GAD-65 Ab",
        "containers": "",
        "analytes": "GAD-65 Ab Ref"
    },
    {
        "code": "100024",
        "name": "Gamma-Glutamyl Transferase (GGT)",
        "containers": "tiger_and_gold_tube",
        "analytes": "Gamma-Glutamyl Transferase (GGT)"
    },
    {
        "code": "140013",
        "name": "Gardnerella Vaginalis",
        "containers": "",
        "analytes": "Gardnerella Vaginalis"
    },
    {
        "code": "170081",
        "name": "Gastrin",
        "containers": "",
        "analytes": "Gastrin Ref"
    },
    {
        "code": "540028",
        "name": "Gastrointestinal Panels",
        "containers": "stool_container",
        "analytes": "Adenovirus; Astrovirus; Campylobpacter Sp; Clostridium Difficile Binary Toxin A/B; Clostridium Difficile Toxin A; Clostridium Difficile Toxin B; Cryptosporidium Spp.; Cyclospora Cayetanensis; Entamoeba Histolytica; Enteroaggregative E. Coli (EAEC); Enteropathogenic E. Coli (EPEC); Enterotoxigenic E. Coli (ETEC); Giardia Lamblia; Norovirus(GI/GII); Plesiomonas Shigelloides; Rotavirus (A); Salmonella Spp; Sapovirus (GI/GII/GIV/GV); Shiga Toxin Producing E. Coli (STEC); Shigella / Enteroinvasive E. Coli (EIEC); Vibrio Cholerae; Vibrio Parahaemolyticus; Yersinia Enterocolitica"
    },
    {
        "code": "540027",
        "name": "Gastrointestinal Panels w/Antibiotic Resistance",
        "containers": "stool_container",
        "analytes": "Adenovirus; Astrovirus; Campylobpacter Sp; Clostridium Difficile Binary Toxin A/B; Clostridium Difficile Toxin A; Clostridium Difficile Toxin B; Cryptosporidium Spp.; CTX-M ESBL - Beta Lactamase; Cyclospora Cayetanensis; Entamoeba Histolytica; Enteroaggregative E. Coli (EAEC); Enteropathogenic E. Coli (EPEC); Enterotoxigenic E. Coli (ETEC); Fluoroquinolone (Qnr); Giardia Lamblia; IMP - Carbapenem; KPC - Carbapenem; Methicillin (mecA+mecC); NDM - Carbapenem; Norovirus(GI/GII); OXA - 48 - Carbapenem; Plesiomonas Shigelloides; Rotavirus (A); Salmonella Spp; Sapovirus (GI/GII/GIV/GV); Shiga Toxin Producing E. Coli (STEC); Shigella / Enteroinvasive E. Coli (EIEC); Sulfonamide (Sul); Trimethoprim (dfrA); Van A - Vancomycin; Van B - Vancomycin; Vibrio Cholerae; Vibrio Parahaemolyticus; VIM - Carbapenem; Yersinia Enterocolitica"
    },
    {
        "code": "540013",
        "name": "GC/CT Panel",
        "containers": "",
        "analytes": "Chlamydia Trachomatis; Neisseria Gonorrhoeae"
    },
    {
        "code": "540012",
        "name": "GC/CT/TV Panel",
        "containers": "",
        "analytes": "Chlamydia Trachomatis; Neisseria Gonorrhoeae; Trichomonas Vaginalis"
    },
    {
        "code": "500008",
        "name": "General Health Profile",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Amylase; Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; C-Reactive Protein, High Sensitivity (CRP-HS); Calcium; Carbon Dioxide; Chloride; Cholesterol; Clarity; Color; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Ferritin; Folic Acid (Folate); Gamma-Glutamyl Transferase (GGT); Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Hemoglobin A1c, Whole Blood; Iron Total; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lipase; Lymphocytes Absolute; Lymphocytes Percentage; Magnesium; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Mucous; Neutrophils Absolute; Neutrophils Percentage; Nitrite; Parathyroid Hormone (PTH); pH, Urine; Phosphorus; Platelets Count; Potassium; Protein, Urine; RDW; Red Blood Cell, Urine; Red Blood Cells; Sedimentation Rate, Westergren (ESR); Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Total Iron Binding Capacity (TIBC); Total Protein; Transferrin; Triglycerides; Triiodothyronine (T3); Urobilinogen; Vitamin B12; Vitamin D, 25-Hydroxy; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "170305",
        "name": "Genital Culture, Routine",
        "containers": "",
        "analytes": "Genital Culture, Routine Ref"
    },
    {
        "code": "140152",
        "name": "Giardia Lamblia",
        "containers": "",
        "analytes": "Giardia Lamblia"
    },
    {
        "code": "170395",
        "name": "Giardia lamblia Direct Detection EIA",
        "containers": "",
        "analytes": "Giardia lamblia Direct Detection EIA Ref"
    },
    {
        "code": "170082",
        "name": "Gliadin Ab IgG/IgA",
        "containers": "",
        "analytes": "Gliadin Ab IgG/IgA Ref"
    },
    {
        "code": "100061",
        "name": "Glomerular Filtration Rate (Estimated) - Updated",
        "containers": "",
        "analytes": "Creatinine Serum; Glomerular Filtration Rate (Estimated) - Updated"
    },
    {
        "code": "170083",
        "name": "Glucagon",
        "containers": "",
        "analytes": "Glucagon Ref"
    },
    {
        "code": "100028",
        "name": "Glucose",
        "containers": "tiger_and_gold_tube",
        "analytes": "Glucose"
    },
    {
        "code": "170079",
        "name": "Glucose 6-Phosphate Dehydrogenase (G6PD)",
        "containers": "",
        "analytes": "Glucose 6-Phosphate Dehydrogenase (G6PD) Ref"
    },
    {
        "code": "170360",
        "name": "Glutamic Acid Decarboxylase (GAD) Autoantibody",
        "containers": "",
        "analytes": "Glutamic Acid Decarboxylase (GAD) Autoantibody Ref"
    },
    {
        "code": "170376",
        "name": "Gluten, IgE",
        "containers": "",
        "analytes": "Gluten, IgE Ref"
    },
    {
        "code": "130009",
        "name": "Gram Stain",
        "containers": "",
        "analytes": "Gram Stain"
    },
    {
        "code": "170326",
        "name": "Grape, Allergen",
        "containers": "",
        "analytes": "Grape, Allergen Ref"
    },
    {
        "code": "170289",
        "name": "Gynecologic Pap Test w/o HPV",
        "containers": "thin_prep_vial",
        "analytes": "Gynecologic Pap Test w/o HPV Ref"
    },
    {
        "code": "170230",
        "name": "Gynecologic Pap Test, Thin Prep w/HPV",
        "containers": "thin_prep_vial",
        "analytes": "Gynecologic Pap Test, Thin Prep Ref"
    },
    {
        "code": "120046",
        "name": "H. Pylori Abs, IgG Qualitative",
        "containers": "",
        "analytes": "H. Pylori Abs, IgG Qualitative"
    },
    {
        "code": "170084",
        "name": "H. Pylori Ag Stool",
        "containers": "",
        "analytes": "H. Pylori Ag Stool Ref"
    },
    {
        "code": "170085",
        "name": "H. Pylori Breath Test",
        "containers": "",
        "analytes": "H. Pylori Breath Test Ref"
    },
    {
        "code": "540030",
        "name": "H. Pylori w/ Clarithromycin Resistance",
        "containers": "stool_container",
        "analytes": "Clarithromycin Resistance; H. Pylori"
    },
    {
        "code": "140039",
        "name": "Haemophilus Influenzae",
        "containers": "",
        "analytes": "Haemophilus Influenzae"
    },
    {
        "code": "170217",
        "name": "HBV DNA Qn",
        "containers": "",
        "analytes": "HBV DNA Qn Ref"
    },
    {
        "code": "100052",
        "name": "HCG Qualitative, Serum",
        "containers": "",
        "analytes": "HCG Qualitative, Serum"
    },
    {
        "code": "100053",
        "name": "HCG Qualitative, Ur",
        "containers": "",
        "analytes": "HCG Qualitative, Ur"
    },
    {
        "code": "170321",
        "name": "HCG Tumor Marker",
        "containers": "",
        "analytes": "HCG Tumor Marker Ref"
    },
    {
        "code": "120007",
        "name": "HCG, Total, Quantitative",
        "containers": "",
        "analytes": "HCG, Total, Quantitative"
    },
    {
        "code": "100029",
        "name": "HDL Cholesterol",
        "containers": "",
        "analytes": "HDL Cholesterol"
    },
    {
        "code": "170219",
        "name": "Heavy Metals Profile II, Whole Blood",
        "containers": "",
        "analytes": "Heavy Metals Profile II, Whole Blood Ref"
    },
    {
        "code": "170281",
        "name": "Helper T-Lymphocyte Marker CD4",
        "containers": "",
        "analytes": "Helper T-Lymphocyte Marker CD4 Ref"
    },
    {
        "code": "110001",
        "name": "Hematocrit",
        "containers": "",
        "analytes": "Hematocrit"
    },
    {
        "code": "110002",
        "name": "Hemoglobin",
        "containers": "",
        "analytes": "Hemoglobin"
    },
    {
        "code": "110009",
        "name": "Hemoglobin A1c",
        "containers": "lavender_tube",
        "analytes": "Hemoglobin A1c, Whole Blood"
    },
    {
        "code": "170087",
        "name": "Hemoglobinopathy Evaluation",
        "containers": "",
        "analytes": "Hemoglobinopathy Evaluation Ref"
    },
    {
        "code": "170088",
        "name": "Hep B DNA by PCR, Qt",
        "containers": "",
        "analytes": "Hep B DNA by PCR, Qt Ref"
    },
    {
        "code": "170089",
        "name": "Hep B Surface Ab Qn",
        "containers": "",
        "analytes": "Hep B Surface Ab Qn Ref"
    },
    {
        "code": "170090",
        "name": "Hep C Ab w/RF HCV",
        "containers": "",
        "analytes": "Hep C Ab w/RF HCV Ref"
    },
    {
        "code": "170091",
        "name": "Hep C RNA By PCR, Qt",
        "containers": "",
        "analytes": "Hep C RNA By PCR, Qt Ref"
    },
    {
        "code": "120050",
        "name": "Hepatitis A Ab IgM",
        "containers": "",
        "analytes": "Hepatitis A Ab IgM"
    },
    {
        "code": "120054",
        "name": "Hepatitis A Ab Total (IgG & IgM)",
        "containers": "",
        "analytes": "Hepatitis A Ab Total (IgG & IgM)"
    },
    {
        "code": "120055",
        "name": "Hepatitis B Core Ab Total",
        "containers": "",
        "analytes": "Hepatitis B Core Ab Total"
    },
    {
        "code": "120048",
        "name": "Hepatitis B Core Ab, IgM",
        "containers": "",
        "analytes": "Hepatitis B Core Ab, IgM"
    },
    {
        "code": "120047",
        "name": "Hepatitis B Surface Ab",
        "containers": "",
        "analytes": "Hepatitis B Surface Ab"
    },
    {
        "code": "120059",
        "name": "Hepatitis B Surface Ag",
        "containers": "",
        "analytes": "Hepatitis B Surface Ag"
    },
    {
        "code": "170220",
        "name": "Hepatitis Be Ab",
        "containers": "",
        "analytes": "Hepatitis B Ab Ref"
    },
    {
        "code": "170354",
        "name": "Hepatitis Be Antigen",
        "containers": "",
        "analytes": "Hepatitis B Antigen Ref"
    },
    {
        "code": "170351",
        "name": "Hepatitis C Virus (HCV) FibroSure",
        "containers": "",
        "analytes": "Hepatitis C Virus (HCV) FibroSure Ref"
    },
    {
        "code": "170352",
        "name": "Hepatitis C Virus (HCV) Genotyping",
        "containers": "",
        "analytes": "Hepatitis C Virus (HCV) Genotyping Ref"
    },
    {
        "code": "120049",
        "name": "Hepatitis C Virus Ab",
        "containers": "tiger_and_gold_tube",
        "analytes": "Hepatitis C Virus Ab"
    },
    {
        "code": "170361",
        "name": "Hepatitis C Virus NS5A Drug Resistance Assay",
        "containers": "",
        "analytes": "Hepatitis C Virus NS5A Drug Resistance Assay Ref"
    },
    {
        "code": "170304",
        "name": "Hepatitis D Virus (HDV) Total",
        "containers": "",
        "analytes": "Hepatitis D Virus (HDV) Total Ref"
    },
    {
        "code": "500012",
        "name": "Hepatitis Panel",
        "containers": "",
        "analytes": "Hepatitis A Ab IgM; Hepatitis A Ab Total (IgG & IgM); Hepatitis B Core Ab Total; Hepatitis B Core Ab, IgM; Hepatitis B Surface Ab; Hepatitis B Surface Ag; Hepatitis C Virus Ab"
    },
    {
        "code": "500036",
        "name": "Hepatitis Panel - Acute",
        "containers": "",
        "analytes": "Hepatitis A Ab IgM; Hepatitis B Core Ab, IgM; Hepatitis B Surface Ag; Hepatitis C Virus Ab"
    },
    {
        "code": "170389",
        "name": "HER-2/neu IHC Breast Paraffin",
        "containers": "",
        "analytes": "HER-2/neu IHC Breast Paraffin Ref"
    },
    {
        "code": "550013",
        "name": "Heroin",
        "containers": "",
        "analytes": "6-MAM (Heroin met.)"
    },
    {
        "code": "560011",
        "name": "Heroin - Oral",
        "containers": "",
        "analytes": "6-MAM (Heroin met.) - Oral"
    },
    {
        "code": "120052",
        "name": "Herpes Simplex Virus (HSV) 1 Ab, IgG",
        "containers": "tiger_and_gold_tube",
        "analytes": "Herpes Simplex Virus (HSV) 1 Ab, IgG"
    },
    {
        "code": "120053",
        "name": "Herpes Simplex Virus (HSV) 2 Ab, IgG",
        "containers": "tiger_and_gold_tube",
        "analytes": "Herpes Simplex Virus (HSV) 2 Ab, IgG"
    },
    {
        "code": "140169",
        "name": "Herpes Simplex Virus 1",
        "containers": "",
        "analytes": "Herpes Simplex Virus 1"
    },
    {
        "code": "140073",
        "name": "Herpes Simplex Virus 1",
        "containers": "",
        "analytes": "Herpes Simplex Virus 1"
    },
    {
        "code": "140170",
        "name": "Herpes Simplex Virus 2",
        "containers": "",
        "analytes": "Herpes Simplex Virus 2"
    },
    {
        "code": "140074",
        "name": "Herpes Simplex Virus 2",
        "containers": "",
        "analytes": "Herpes Simplex Virus 2"
    },
    {
        "code": "170322",
        "name": "Histamine Determination, Plasma",
        "containers": "",
        "analytes": "Histamine Determination, Plasma Ref"
    },
    {
        "code": "170093",
        "name": "Histoplasma Ab, Immunodiffusion",
        "containers": "",
        "analytes": "Histoplasma Ab, Immunodiffusion Ref"
    },
    {
        "code": "120051",
        "name": "HIV Combo Ag/Ab EIA",
        "containers": "tiger_and_gold_tube",
        "analytes": "HIV Combo Ag/Ab"
    },
    {
        "code": "170348",
        "name": "HIV p24 Ag/Ab W/Reflex",
        "containers": "",
        "analytes": "HIV p24 Ag/Ab W/Reflex Ref"
    },
    {
        "code": "170094",
        "name": "HIV-1 RNA, Qn, RT-PCR",
        "containers": "",
        "analytes": "HIV-1 RNA, Qn, RT-PCR Ref"
    },
    {
        "code": "170346",
        "name": "HLA B5107 Abacavir Hypersensitivity",
        "containers": "",
        "analytes": "HLA B5107 Abacavir Hypersensitivity Ref"
    },
    {
        "code": "170095",
        "name": "HLA-B27 Ag",
        "containers": "",
        "analytes": "HLA-B27 Ag Ref"
    },
    {
        "code": "120040",
        "name": "Homocysteine",
        "containers": "tiger_and_gold_tube",
        "analytes": "Homocysteine"
    },
    {
        "code": "170309",
        "name": "Horse Dander",
        "containers": "",
        "analytes": "Horse Dander Ref"
    },
    {
        "code": "170240",
        "name": "HSV 1/2, DNA PCR",
        "containers": "",
        "analytes": "HSV 1/2, DNA PCR Ref"
    },
    {
        "code": "170099",
        "name": "HTLV I/II w/RF Conf",
        "containers": "",
        "analytes": "HTLV I/II w/RF Conf Ref"
    },
    {
        "code": "140040",
        "name": "Human Bocavirus",
        "containers": "",
        "analytes": "Human Bocavirus"
    },
    {
        "code": "140041",
        "name": "Human Corona 229E",
        "containers": "",
        "analytes": "Human Corona 229E"
    },
    {
        "code": "140042",
        "name": "Human Corona HKU1",
        "containers": "",
        "analytes": "Human Corona HKU1"
    },
    {
        "code": "140043",
        "name": "Human Corona NL63",
        "containers": "",
        "analytes": "Human Corona NL63"
    },
    {
        "code": "140044",
        "name": "Human Corona OC43",
        "containers": "",
        "analytes": "Human Corona OC43"
    },
    {
        "code": "140045",
        "name": "Human Enterovirus/Human Rhinovirus",
        "containers": "",
        "analytes": "Human Enterovirus/Human Rhinovirus"
    },
    {
        "code": "170092",
        "name": "Human Growth Hormone (hGH)",
        "containers": "",
        "analytes": "Human Growth Hormone (hGH) Ref"
    },
    {
        "code": "170244",
        "name": "Human Herpes Virus 6 PCR",
        "containers": "",
        "analytes": "Human Herpes Virus 6 PCR Ref"
    },
    {
        "code": "170349",
        "name": "Human Immunodeficiency Virus 1 (HIV-1) PhenoSense GT",
        "containers": "",
        "analytes": "Human Immunodeficiency Virus 1 (HIV-1) PhenoSense GT Ref"
    },
    {
        "code": "140046",
        "name": "Human Metapneumoviruses",
        "containers": "",
        "analytes": "Human Metapneumoviruses"
    },
    {
        "code": "140047",
        "name": "Human Parechovirus",
        "containers": "",
        "analytes": "Human Parechovirus"
    },
    {
        "code": "120039",
        "name": "Human Sex Hormone Binding Globulin (SHBG)",
        "containers": "",
        "analytes": "Human Sex Hormone Binding Globulin (SHBG)"
    },
    {
        "code": "150051",
        "name": "Hydrocodone",
        "containers": "",
        "analytes": "Hydrocodone"
    },
    {
        "code": "160017",
        "name": "Hydrocodone - Oral",
        "containers": "",
        "analytes": "Hydrocodone - Oral"
    },
    {
        "code": "150052",
        "name": "Hydromorphone",
        "containers": "",
        "analytes": "Hydromorphone"
    },
    {
        "code": "160018",
        "name": "Hydromorphone - Oral",
        "containers": "",
        "analytes": "Hydromorphone - Oral"
    },
    {
        "code": "500022",
        "name": "Hypertension/Cardiac Profile",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; C-Reactive Protein, High Sensitivity (CRP-HS); Calcium; Carbon Dioxide; Chloride; Cholesterol; Clarity; Color; Cortisol; CPK Total; Creatinine Serum; Creatinine Ur; Eosinophils Absolute; Eosinophils Percentage; Ferritin; Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Hemoglobin A1c, Whole Blood; Iron Total; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lymphocytes Absolute; Lymphocytes Percentage; Magnesium; MCH; MCHC; MCV; Microalb/Creat Ratio; Microalbumin Ur; Monocytes Absolute; Monocytes Percentage; Mucous; Neutrophils Absolute; Neutrophils Percentage; Nitrite; Parathyroid Hormone (PTH); pH, Urine; Platelets Count; Potassium; Protein, Urine; RDW; Red Blood Cell, Urine; Red Blood Cells; Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Total Iron Binding Capacity (TIBC); Total Protein; Transferrin; Triglycerides; Triiodothyronine (T3); Uric Acid; Urobilinogen; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "170306",
        "name": "IA2 Autoantibodies (Endocrine Sciences)",
        "containers": "",
        "analytes": "IA2 Autoantibodies (Endocrine Sciences) Ref"
    },
    {
        "code": "170221",
        "name": "IGF Binding Protein-1 (IGFBP-1)",
        "containers": "",
        "analytes": "IGF Binding Protein-1 (IGFBP-1) Ref"
    },
    {
        "code": "170101",
        "name": "IGF Binding Protein-3 (IGFBP-3)",
        "containers": "",
        "analytes": "IGF Binding Protein-3 (IGFBP-3) Ref"
    },
    {
        "code": "170102",
        "name": "IGF-1, LCMS",
        "containers": "",
        "analytes": "IGF-1, LCMS Ref"
    },
    {
        "code": "170103",
        "name": "IgG Subclass 4",
        "containers": "",
        "analytes": "IgG Subclass 4 Ref"
    },
    {
        "code": "550026",
        "name": "Illicit Drugs",
        "containers": "",
        "analytes": "6-MAM (Heroin met.); Benzoylecgonine; MDA; MDMA; Phencyclidine (PCP); THC-COOH"
    },
    {
        "code": "550028",
        "name": "Illicit Drugs - No THC",
        "containers": "",
        "analytes": "6-MAM (Heroin met.); Benzoylecgonine; MDA; MDMA; Phencyclidine (PCP)"
    },
    {
        "code": "560020",
        "name": "Illicit Drugs - No THC - Oral",
        "containers": "",
        "analytes": "6-MAM (Heroin met.) - Oral; Benzoylecgonine - Oral; MDA - Oral; MDMA - Oral; Phencyclidine (PCP) - Oral"
    },
    {
        "code": "560018",
        "name": "Illicit Drugs - Oral",
        "containers": "",
        "analytes": "6-MAM (Heroin met.) - Oral; Benzoylecgonine - Oral; MDA - Oral; MDMA - Oral; Phencyclidine (PCP) - Oral; THC-COOH  - Oral"
    },
    {
        "code": "170104",
        "name": "Imcap, Crab (F23)",
        "containers": "",
        "analytes": "Imcap, Crab (F23) Ref"
    },
    {
        "code": "170105",
        "name": "Imcap, Egg White (F1)",
        "containers": "",
        "analytes": "Imcap, Egg White (F1) Ref"
    },
    {
        "code": "170106",
        "name": "Imcap, Egg Yolk (F75)",
        "containers": "",
        "analytes": "Imcap, Egg Yolk (F75) Ref"
    },
    {
        "code": "170107",
        "name": "Imcap, Lobster (F80)",
        "containers": "",
        "analytes": "Imcap, Lobster (F80) Ref"
    },
    {
        "code": "170108",
        "name": "Imcap, Paper Wasp (I4)",
        "containers": "",
        "analytes": "Imcap, Paper Wasp (I4) Ref"
    },
    {
        "code": "170109",
        "name": "Imcap, Peanut (F13)",
        "containers": "",
        "analytes": "Imcap, Peanut (F13) Ref"
    },
    {
        "code": "170110",
        "name": "Imcap, Tomato (F25)",
        "containers": "",
        "analytes": "Imcap, Tomato (F25) Ref"
    },
    {
        "code": "150056",
        "name": "Imipramine",
        "containers": "",
        "analytes": "Imipramine"
    },
    {
        "code": "170111",
        "name": "Immunofixation (IFE), Serum",
        "containers": "",
        "analytes": "Immunofixation (IFE), Serum Ref"
    },
    {
        "code": "170313",
        "name": "Immunofixation (IFE), Urine",
        "containers": "",
        "analytes": "Immunofixation (IFE), Urine Ref"
    },
    {
        "code": "170222",
        "name": "Immunofixation (IFE)/Prot. Electro, Serum",
        "containers": "",
        "analytes": "Immunofixation (IFE)/Prot. Electro, Serum Ref"
    },
    {
        "code": "170112",
        "name": "Immunoglobulin A (IgA)",
        "containers": "",
        "analytes": "Immunoglobulin A (IgA) Ref"
    },
    {
        "code": "170275",
        "name": "Immunoglobulin A, Subclasses (1-2)",
        "containers": "",
        "analytes": "IgA, Subclass 1 Ref; IgA, Subclass 2 Ref; Immunoglobulin A, Qn Ref"
    },
    {
        "code": "170241",
        "name": "Immunoglobulin D, Quant, Serum",
        "containers": "",
        "analytes": "Immunoglobulin D, Quant, Serum Ref"
    },
    {
        "code": "170113",
        "name": "Immunoglobulin E (IgE)",
        "containers": "",
        "analytes": "Immunoglobulin E (IgE) Ref"
    },
    {
        "code": "170114",
        "name": "Immunoglobulin G (IgG)",
        "containers": "",
        "analytes": "Immunoglobulin G (IgG) Ref"
    },
    {
        "code": "170273",
        "name": "Immunoglobulin G, Subclasses (1-4)",
        "containers": "",
        "analytes": "Immunoglobulin G, Subclasses (1-4) Ref"
    },
    {
        "code": "170115",
        "name": "Immunoglobulin M (IgM)",
        "containers": "",
        "analytes": "Immunoglobulin M (IgM) Ref"
    },
    {
        "code": "140048",
        "name": "Influenza A",
        "containers": "",
        "analytes": "Influenza A"
    },
    {
        "code": "140034",
        "name": "Influenza A, Influenza B, NAA",
        "containers": "",
        "analytes": "Influenza A, Influenza B, NAA"
    },
    {
        "code": "140033",
        "name": "Influenza A, Influenza B, NAA",
        "containers": "",
        "analytes": "Influenza A, Influenza B, NAA"
    },
    {
        "code": "140049",
        "name": "Influenza B",
        "containers": "",
        "analytes": "Influenza B"
    },
    {
        "code": "120041",
        "name": "Insulin",
        "containers": "",
        "analytes": "Insulin"
    },
    {
        "code": "170117",
        "name": "Insulin, Intact, LCMS",
        "containers": "",
        "analytes": "Insulin, Intact, LCMS Ref"
    },
    {
        "code": "170118",
        "name": "Interleukin-6 (IL-6), Serum",
        "containers": "",
        "analytes": "Interleukin-6 (IL-6), Serum Ref"
    },
    {
        "code": "170260",
        "name": "Intrinsic Factor Blocking Antibodies",
        "containers": "",
        "analytes": "Intrinsic Factor Blocking Antibodies Ref"
    },
    {
        "code": "170121",
        "name": "Iodine Random, Ur",
        "containers": "",
        "analytes": "Iodine Random, Ur Ref"
    },
    {
        "code": "170223",
        "name": "Iodine, Serum or Plasma",
        "containers": "",
        "analytes": "Iodine Serum or Plasma Ref"
    },
    {
        "code": "100032",
        "name": "Iron Total",
        "containers": "tiger_and_gold_tube",
        "analytes": "Iron Total"
    },
    {
        "code": "170248",
        "name": "Isohemagglutinin Titer",
        "containers": "",
        "analytes": "Isohemagglutinin Titer Ref"
    },
    {
        "code": "170122",
        "name": "IV-PATH, G&M, 1SP, PC",
        "containers": "",
        "analytes": "IV-PATH, G&M, 1SP, PC Ref"
    },
    {
        "code": "170123",
        "name": "IV-PATH, G&M, 1SP, TC",
        "containers": "",
        "analytes": "IV-PATH, G&M, 1SP, TC Ref"
    },
    {
        "code": "170124",
        "name": "IV-PATH, G&M, 2SP, PC",
        "containers": "",
        "analytes": "IV-PATH, G&M, 2SP, PC Ref"
    },
    {
        "code": "170125",
        "name": "IV-PATH, G&M, 2SP, TC",
        "containers": "",
        "analytes": "IV-PATH, G&M, 2SP, TC Ref"
    },
    {
        "code": "170126",
        "name": "IV-PATH, G&M, 3SP, PC",
        "containers": "",
        "analytes": "IV-PATH, G&M, 3SP, PC Ref"
    },
    {
        "code": "170127",
        "name": "IV-PATH, G&M, 3SP, TC",
        "containers": "",
        "analytes": "IV-PATH, G&M, 3SP, TC Ref"
    },
    {
        "code": "150057",
        "name": "JWH-018 4-Hydroxypentyl",
        "containers": "",
        "analytes": "JWH-018 4-Hydroxypentyl"
    },
    {
        "code": "150058",
        "name": "JWH-073 3-Hydroxybutyl",
        "containers": "",
        "analytes": "JWH-073 3-Hydroxybutyl"
    },
    {
        "code": "170130",
        "name": "K082-IgE Latex",
        "containers": "",
        "analytes": "K082-IgE Latex Ref"
    },
    {
        "code": "170128",
        "name": "Kappa/Lambda w/Ratio",
        "containers": "",
        "analytes": "Kappa/Lambda w/Ratio Ref"
    },
    {
        "code": "560012",
        "name": "Ketamin - Oral",
        "containers": "",
        "analytes": "Ketamine - Oral; Norketamine - Oral"
    },
    {
        "code": "150059",
        "name": "Ketamine",
        "containers": "",
        "analytes": "Ketamine"
    },
    {
        "code": "160019",
        "name": "Ketamine - Oral",
        "containers": "",
        "analytes": "Ketamine - Oral"
    },
    {
        "code": "500029",
        "name": "Kidney Profile",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; C-Reactive Protein, High Sensitivity (CRP-HS); Calcium; Carbon Dioxide; Chloride; Cholesterol; Clarity; Color; Cortisol; Creatinine Serum; Culture, Urine; Eosinophils Absolute; Eosinophils Percentage; Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Mucous; Neutrophils Absolute; Neutrophils Percentage; Nitrite; pH, Urine; Platelets Count; Potassium; Protein, Urine; RDW; Red Blood Cell, Urine; Red Blood Cells; Sedimentation Rate, Westergren (ESR); Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Total Protein; Triglycerides; Urobilinogen; Vitamin D, 25-Hydroxy; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "140171",
        "name": "Klebsiella aerogenes",
        "containers": "",
        "analytes": "Klebsiella Aerogenes"
    },
    {
        "code": "140063",
        "name": "Klebsiella Aerogenes",
        "containers": "",
        "analytes": "Klebsiella Aerogenes"
    },
    {
        "code": "140172",
        "name": "Klebsiella oxytoca",
        "containers": "",
        "analytes": "Klebsiella Oxytoca"
    },
    {
        "code": "140064",
        "name": "Klebsiella Oxytoca",
        "containers": "",
        "analytes": "Klebsiella Oxytoca"
    },
    {
        "code": "140173",
        "name": "Klebsiella pneumoniae",
        "containers": "",
        "analytes": "Klebsiella pneumoniae"
    },
    {
        "code": "140065",
        "name": "Klebsiella pneumoniae",
        "containers": "",
        "analytes": "Klebsiella pneumoniae"
    },
    {
        "code": "170129",
        "name": "Lacosamide",
        "containers": "",
        "analytes": "Lacosamide Ref"
    },
    {
        "code": "100033",
        "name": "Lactic Acid Dehydrogenase (LDH)",
        "containers": "",
        "analytes": "Lactic Acid Dehydrogenase (LDH)"
    },
    {
        "code": "170372",
        "name": "Lactoferrin, Fecal, Quantitative",
        "containers": "",
        "analytes": "Lactoferrin, Fecal, Quantitative Ref"
    },
    {
        "code": "170267",
        "name": "Lamotrigine, Serum",
        "containers": "",
        "analytes": "Lamotrigine, Serum Ref"
    },
    {
        "code": "100034",
        "name": "LDL Cholesterol(Calculated)",
        "containers": "",
        "analytes": "LDL Cholesterol(Calculated)"
    },
    {
        "code": "170303",
        "name": "LDL-P",
        "containers": "",
        "analytes": "LDL-P Ref"
    },
    {
        "code": "170132",
        "name": "Lead, Blood",
        "containers": "dark_blue_tube",
        "analytes": "Lead, Blood Ref"
    },
    {
        "code": "140050",
        "name": "Legionella pneumophila",
        "containers": "",
        "analytes": "Legionella pneumophila"
    },
    {
        "code": "170134",
        "name": "Levetiracetam Keppra)",
        "containers": "",
        "analytes": "Levetiracetam (Keppra) Ref"
    },
    {
        "code": "100035",
        "name": "Lipase",
        "containers": "tiger_and_gold_tube",
        "analytes": "Lipase"
    },
    {
        "code": "170283",
        "name": "Lipid Cascade With Reflex to Lipoprotein Particle Assessment by NMR",
        "containers": "",
        "analytes": "Lipid Cascade With Reflex to Lipoprotein Particle Assessment by NMR Ref"
    },
    {
        "code": "500006",
        "name": "Lipid Panel",
        "containers": "",
        "analytes": "Cholesterol; HDL Cholesterol; HDL/Cholesterol Ratio; LDL Cholesterol(Calculated); Triglycerides; VLDL Cholesterol"
    },
    {
        "code": "170296",
        "name": "Lipoprotein-associated Phospholipase A2 Activity (PLAC)",
        "containers": "",
        "analytes": "Lipoprotein-associated Phospholipase A2 Activity (PLAC) Ref"
    },
    {
        "code": "170135",
        "name": "Lipoprotein(a)",
        "containers": "",
        "analytes": "Lipoprotein(a) Ref"
    },
    {
        "code": "170136",
        "name": "Lithium",
        "containers": "red_tube",
        "analytes": "Lithium Ref"
    },
    {
        "code": "540040",
        "name": "Liver Function Test (LFT)",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bilirubin Direct (D-Bili); Bilirubin, Total (T-Bili); Gamma-Glutamyl Transferase (GGT); Total Protein"
    },
    {
        "code": "500009",
        "name": "Liver Profile",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Amylase; Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin Direct (D-Bili); Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; C-Reactive Protein, High Sensitivity (CRP-HS); Calcium; Carbon Dioxide; Chloride; Cholesterol; Clarity; Color; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Ferritin; Gamma-Glutamyl Transferase (GGT); Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Hepatitis A Ab IgM; Hepatitis A Ab Total (IgG & IgM); Hepatitis B Core Ab Total; Hepatitis B Core Ab, IgM; Hepatitis B Surface Ab; Hepatitis B Surface Ag; Hepatitis C Virus Ab; International Normalized Ratio (INR); Iron Total; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lipase; Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Mucous; Neutrophils Absolute; Neutrophils Percentage; Nitrite; Partial Thromboplastin Time (PTT); pH, Urine; Platelets Count; Potassium; Protein, Urine; Prothrombin Time (PT); RDW; Red Blood Cell, Urine; Red Blood Cells; Sedimentation Rate, Westergren (ESR); Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Total Iron Binding Capacity (TIBC); Total Protein; Transferrin; Triglycerides; Triiodothyronine (T3); Urobilinogen; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "150060",
        "name": "Lorazepam",
        "containers": "",
        "analytes": "Lorazepam"
    },
    {
        "code": "160020",
        "name": "Lorazepam - Oral",
        "containers": "",
        "analytes": "Lorazepam - Oral"
    },
    {
        "code": "170137",
        "name": "Lupus Anticoag w/RF",
        "containers": "",
        "analytes": "Lupus Anticoag w/RF Ref"
    },
    {
        "code": "120008",
        "name": "Luteinizing Hormone (LH)",
        "containers": "",
        "analytes": "Luteinizing Hormone (LH)"
    },
    {
        "code": "170358",
        "name": "Lyme Antibodies, Modified 2-Tier Testing Profile",
        "containers": "",
        "analytes": "Lyme Antibodies, Modified 2-Tier Testing Profile Ref"
    },
    {
        "code": "170140",
        "name": "Lyme Dis IgG/IgM Blot",
        "containers": "",
        "analytes": "Lyme Dis IgG/IgM Blot Ref"
    },
    {
        "code": "170141",
        "name": "Lymphocyte Subset 6",
        "containers": "",
        "analytes": "Lymphocyte Subset 6 Ref"
    },
    {
        "code": "170142",
        "name": "Lysozyme, Serum",
        "containers": "",
        "analytes": "Lysozyme, Serum Ref"
    },
    {
        "code": "170143",
        "name": "M Pneumo IgG Ab",
        "containers": "",
        "analytes": "M Pneumo IgG Ab Ref"
    },
    {
        "code": "170327",
        "name": "Macadamia Nut, Allergen",
        "containers": "",
        "analytes": "Macadamia Nut, Allergen Ref"
    },
    {
        "code": "170307",
        "name": "Macroprolactin",
        "containers": "",
        "analytes": "Macroprolactin Ref"
    },
    {
        "code": "100037",
        "name": "Magnesium",
        "containers": "tiger_and_gold_tube",
        "analytes": "Magnesium"
    },
    {
        "code": "170144",
        "name": "Magnesium w/o Creat 24H Ur",
        "containers": "",
        "analytes": "Magnesium w/o Creat 24H Ur Ref"
    },
    {
        "code": "500025",
        "name": "Male Endocrine Profile",
        "containers": "",
        "analytes": "Cortisol; Luteinizing Hormone (LH); Progesterone; Prolactin; Testosterone, Total; Thyroid Stimulating Hormone (TSH); Thyroxine (T4)"
    },
    {
        "code": "150186",
        "name": "Marijuana (THC)",
        "containers": "",
        "analytes": "Marijuana (THC) - Cup"
    },
    {
        "code": "150174",
        "name": "Marijuana (THC)  - EIA (Military)",
        "containers": "",
        "analytes": "THC - EIA"
    },
    {
        "code": "150166",
        "name": "Marijuana (THC) - Actual",
        "containers": "",
        "analytes": "THC - EIA"
    },
    {
        "code": "150156",
        "name": "Marijuana (THC) - EIA",
        "containers": "",
        "analytes": "THC - EIA"
    },
    {
        "code": "150062",
        "name": "MDA",
        "containers": "",
        "analytes": "MDA"
    },
    {
        "code": "160021",
        "name": "MDA - Oral",
        "containers": "",
        "analytes": "MDA - Oral"
    },
    {
        "code": "150181",
        "name": "MDMA",
        "containers": "",
        "analytes": "MDMA - Cup"
    },
    {
        "code": "150064",
        "name": "MDMA",
        "containers": "",
        "analytes": "MDMA"
    },
    {
        "code": "160022",
        "name": "MDMA - Oral",
        "containers": "",
        "analytes": "MDMA - Oral"
    },
    {
        "code": "150065",
        "name": "MDPV",
        "containers": "",
        "analytes": "MDPV"
    },
    {
        "code": "120060",
        "name": "Measles (Rubeola) - IgG",
        "containers": "",
        "analytes": "Measles (Rubeola) - IgG"
    },
    {
        "code": "170300",
        "name": "Measles (Rubeola) Ab, IgM, S",
        "containers": "",
        "analytes": "Measles (Rubeola) Ab, IgM, S Ref"
    },
    {
        "code": "140203",
        "name": "Measles by qPCR",
        "containers": "",
        "analytes": "Measles by qPCR"
    },
    {
        "code": "170370",
        "name": "Measles RNA Swab",
        "containers": "",
        "analytes": "Measles RNA Swab Ref"
    },
    {
        "code": "140021",
        "name": "Megasphaera 1",
        "containers": "",
        "analytes": "Megasphaera 1 and 2"
    },
    {
        "code": "170284",
        "name": "Melatonin, Serum/Plasma",
        "containers": "",
        "analytes": "Melatonin, Serum/Plasma Ref"
    },
    {
        "code": "170285",
        "name": "Melatonin, Urine",
        "containers": "",
        "analytes": "Melatonin, Urine Ref"
    },
    {
        "code": "150066",
        "name": "Meperidine",
        "containers": "",
        "analytes": "Meperidine"
    },
    {
        "code": "160023",
        "name": "Meperidine - Oral",
        "containers": "",
        "analytes": "Meperidine - Oral"
    },
    {
        "code": "150067",
        "name": "Meprobamate",
        "containers": "",
        "analytes": "Meprobamate"
    },
    {
        "code": "160024",
        "name": "Meprobamate - Oral",
        "containers": "",
        "analytes": "Meprobamate - Oral"
    },
    {
        "code": "170355",
        "name": "Mercury",
        "containers": "",
        "analytes": "Mercury Ref"
    },
    {
        "code": "170145",
        "name": "Metanephrines, Fract",
        "containers": "",
        "analytes": "Metanephrines, Fract Ref"
    },
    {
        "code": "170368",
        "name": "Metanephrines, Fractionated Urine 24h",
        "containers": "",
        "analytes": "Metanephrine Urine 24h Ref; Metanephrine Urine Ref; Normetanephrine Urine 24h Ref; Normetanephrine Urine Ref"
    },
    {
        "code": "170298",
        "name": "Metanephrines, Fractionated, Plasma Free",
        "containers": "",
        "analytes": "Metanephrine, Pl Ref; Normetanephrine, Pl Ref"
    },
    {
        "code": "150183",
        "name": "Methadone (MTD)",
        "containers": "",
        "analytes": "Methadone (MTD) - Cup"
    },
    {
        "code": "150068",
        "name": "Methadone (MTD)",
        "containers": "",
        "analytes": "Methadone"
    },
    {
        "code": "150167",
        "name": "Methadone (MTD) - Actual",
        "containers": "",
        "analytes": "Methadone - EIA"
    },
    {
        "code": "150157",
        "name": "Methadone (MTD) - EIA",
        "containers": "",
        "analytes": "Methadone - EIA"
    },
    {
        "code": "160025",
        "name": "Methadone (MTD) - Oral",
        "containers": "",
        "analytes": "Methadone - Oral"
    },
    {
        "code": "150180",
        "name": "Methamphetamine",
        "containers": "",
        "analytes": "Methamphetamine - Cup"
    },
    {
        "code": "150069",
        "name": "Methamphetamine",
        "containers": "",
        "analytes": "Methamphetamine"
    },
    {
        "code": "160026",
        "name": "Methamphetamine - Oral",
        "containers": "",
        "analytes": "Methamphetamine - Oral"
    },
    {
        "code": "170314",
        "name": "Methaqualone, Screen and Confirmation, Urine",
        "containers": "",
        "analytes": "Methaqualone, Screen and Confirmation, Urine Ref"
    },
    {
        "code": "170146",
        "name": "Methylmalonic Acid",
        "containers": "",
        "analytes": "Methylmalonic Acid Ref"
    },
    {
        "code": "160027",
        "name": "Methylphenidate - Oral",
        "containers": "",
        "analytes": "Methylphenidate - Oral"
    },
    {
        "code": "500032",
        "name": "Microalbumin Panel",
        "containers": "",
        "analytes": "Creatinine Ur; Microalb/Creat Ratio; Microalbumin Ur"
    },
    {
        "code": "100038",
        "name": "Microalbumin, Ur",
        "containers": "",
        "analytes": "Microalbumin Ur"
    },
    {
        "code": "170147",
        "name": "Micronutrient,Vit B1",
        "containers": "",
        "analytes": "Micronutrient,Vit B1 Ref"
    },
    {
        "code": "170148",
        "name": "Milk Component Panel",
        "containers": "",
        "analytes": "Milk Component Panel Ref"
    },
    {
        "code": "170149",
        "name": "Mitochondrial M2",
        "containers": "",
        "analytes": "Mitochondrial M2 Ref"
    },
    {
        "code": "170150",
        "name": "Mitochondrial M2 w/RF",
        "containers": "",
        "analytes": "Mitochondrial M2 w/RF Ref"
    },
    {
        "code": "170291",
        "name": "Mold, Allergen Profile",
        "containers": "",
        "analytes": "Mold, Allergen Profile Ref"
    },
    {
        "code": "170323",
        "name": "Molybdenum, Whole Blood",
        "containers": "",
        "analytes": "Molybdenum, Whole Blood Ref"
    },
    {
        "code": "540018",
        "name": "Monkey Pox Orthopox Species PCR",
        "containers": "",
        "analytes": "Monkey Pox Virus; Orthopox"
    },
    {
        "code": "140098",
        "name": "Monkey Pox Virus",
        "containers": "",
        "analytes": "Monkey Pox Virus"
    },
    {
        "code": "140031",
        "name": "Monkeypox by PCR",
        "containers": "",
        "analytes": "Monkeypox"
    },
    {
        "code": "170367",
        "name": "Mononucleosis Qualitative",
        "containers": "",
        "analytes": "Mononucleosis Qualitative Ref"
    },
    {
        "code": "140066",
        "name": "Moraxella Catarrhalis",
        "containers": "",
        "analytes": "Moraxella Catarrhalis"
    },
    {
        "code": "140174",
        "name": "Morganella morganii",
        "containers": "",
        "analytes": "Morganella Morganii"
    },
    {
        "code": "140087",
        "name": "Morganella Morganii",
        "containers": "",
        "analytes": "Morganella Morganii"
    },
    {
        "code": "150182",
        "name": "Morphine",
        "containers": "",
        "analytes": "Morphine - Cup"
    },
    {
        "code": "150071",
        "name": "Morphine",
        "containers": "",
        "analytes": "Morphine"
    },
    {
        "code": "160029",
        "name": "Morphine - Oral",
        "containers": "",
        "analytes": "Morphine - Oral"
    },
    {
        "code": "170393",
        "name": "MRSA Colonization Screening Culture",
        "containers": "",
        "analytes": "MRSA Colonization Screening Culture Ref"
    },
    {
        "code": "130014",
        "name": "MRSA Screen",
        "containers": "",
        "analytes": "MRSA Screen"
    },
    {
        "code": "170151",
        "name": "Mumps V Ab, IgM",
        "containers": "",
        "analytes": "Mumps V Ab, IgM Ref"
    },
    {
        "code": "120057",
        "name": "Mumps Virus Ab - IgG",
        "containers": "",
        "analytes": "Mumps Virus Ab - IgG"
    },
    {
        "code": "550008",
        "name": "Muscle Relaxants",
        "containers": "",
        "analytes": "Carisoprodol; Cyclobenzaprine; Meprobamate"
    },
    {
        "code": "560006",
        "name": "Muscle Relaxants - Oral",
        "containers": "",
        "analytes": "Carisoprodol - Oral; Cyclobenzaprine - Oral; Meprobamate - Oral"
    },
    {
        "code": "170282",
        "name": "Mushroom",
        "containers": "",
        "analytes": "Mushroom Ref"
    },
    {
        "code": "140178",
        "name": "Mycobacterium abscessus",
        "containers": "",
        "analytes": "Mycobacterium abscessus"
    },
    {
        "code": "140175",
        "name": "Mycobacterium avium Complex",
        "containers": "",
        "analytes": "Mycobacterium avium Complex"
    },
    {
        "code": "140179",
        "name": "Mycobacterium chelonae",
        "containers": "",
        "analytes": "Mycobacterium chelonae"
    },
    {
        "code": "140176",
        "name": "Mycobacterium fortuitum",
        "containers": "",
        "analytes": "Mycobacterium fortuitum"
    },
    {
        "code": "140177",
        "name": "Mycobacterium ulcerans",
        "containers": "",
        "analytes": "Mycobacterium ulcerans"
    },
    {
        "code": "170152",
        "name": "Mycophenolic Acid, Serum",
        "containers": "",
        "analytes": "Mycophenolic Acid, Serum Ref"
    },
    {
        "code": "140075",
        "name": "Mycoplasma Genitalium",
        "containers": "",
        "analytes": "Mycoplasma Genitalium"
    },
    {
        "code": "140076",
        "name": "Mycoplasma Hominis",
        "containers": "",
        "analytes": "Mycoplasma Hominis"
    },
    {
        "code": "140051",
        "name": "Mycoplasma pneumoniae",
        "containers": "",
        "analytes": "Mycoplasma pneumoniae"
    },
    {
        "code": "540041",
        "name": "Mycoplasma/Ureaplasma Panel",
        "containers": "",
        "analytes": "Mycoplasma Genitalium; Mycoplasma Hominis; Ureaplasma Parvum/Urealyticum"
    },
    {
        "code": "170269",
        "name": "Myoglobin",
        "containers": "",
        "analytes": "Myoglobin Ref"
    },
    {
        "code": "170390",
        "name": "N-Telopeptide Cross-links (NTx), Urine",
        "containers": "",
        "analytes": "N-Telopeptide, Urine Ref; N-Telopeptide/Creatinine Ratio Ref"
    },
    {
        "code": "150073",
        "name": "Naloxone",
        "containers": "",
        "analytes": "Naloxone"
    },
    {
        "code": "160030",
        "name": "Naloxone - Oral",
        "containers": "",
        "analytes": "Naloxone - Oral"
    },
    {
        "code": "150074",
        "name": "Naltrexone",
        "containers": "",
        "analytes": "Naltrexone"
    },
    {
        "code": "160031",
        "name": "Naltrexone - Oral",
        "containers": "",
        "analytes": "Naltrexone - Oral"
    },
    {
        "code": "140025",
        "name": "Neisseria Gonorrhoeae",
        "containers": "",
        "analytes": "Neisseria Gonorrhoeae"
    },
    {
        "code": "170345",
        "name": "Neisseria meningitidis IgG",
        "containers": "",
        "analytes": "Neisseria meningitidis IgG Ref"
    },
    {
        "code": "170245",
        "name": "Neuron-specific Enolase, Serum",
        "containers": "",
        "analytes": "Neuron-specific Enolase, Serum Ref"
    },
    {
        "code": "150122",
        "name": "Norbuprenorphine",
        "containers": "",
        "analytes": "Norbuprenorphine"
    },
    {
        "code": "160032",
        "name": "Norbuprenorphine - Oral",
        "containers": "",
        "analytes": "Norbuprenorphine - Oral"
    },
    {
        "code": "150075",
        "name": "Norcodeine",
        "containers": "",
        "analytes": "Norcodeine"
    },
    {
        "code": "150076",
        "name": "Nordiazepam",
        "containers": "",
        "analytes": "Nordiazepam"
    },
    {
        "code": "160033",
        "name": "Nordiazepam - Oral",
        "containers": "",
        "analytes": "Nordiazepam - Oral"
    },
    {
        "code": "150077",
        "name": "Norfentanyl",
        "containers": "",
        "analytes": "Norfentanyl"
    },
    {
        "code": "160034",
        "name": "Norfentanyl - Oral",
        "containers": "",
        "analytes": "Norfentanyl - Oral"
    },
    {
        "code": "150079",
        "name": "Norhydrocodone",
        "containers": "",
        "analytes": "Norhydrocodone"
    },
    {
        "code": "160035",
        "name": "Norhydrocodone - Oral",
        "containers": "",
        "analytes": "Norhydrocodone - Oral"
    },
    {
        "code": "150081",
        "name": "Norketamine",
        "containers": "",
        "analytes": "Norketamine"
    },
    {
        "code": "160036",
        "name": "Norketamine - Oral",
        "containers": "",
        "analytes": "Norketamine - Oral"
    },
    {
        "code": "150082",
        "name": "Normeperidine",
        "containers": "",
        "analytes": "Normeperidine"
    },
    {
        "code": "160037",
        "name": "Normeperidine - Oral",
        "containers": "",
        "analytes": "Normeperidine - Oral"
    },
    {
        "code": "170226",
        "name": "Norovirus Detection, Real-time PCR",
        "containers": "",
        "analytes": "Norovirus Detection, Real-time PCR Ref"
    },
    {
        "code": "140149",
        "name": "Norovirus(GI/GII)",
        "containers": "",
        "analytes": "Norovirus(GI/GII)"
    },
    {
        "code": "150083",
        "name": "Noroxycodone",
        "containers": "",
        "analytes": "Noroxycodone"
    },
    {
        "code": "160038",
        "name": "Noroxycodone - Oral",
        "containers": "",
        "analytes": "Noroxycodone - Oral"
    },
    {
        "code": "150084",
        "name": "Norpropoxyphene",
        "containers": "",
        "analytes": "Norpropoxyphene"
    },
    {
        "code": "150085",
        "name": "Nortriptyline",
        "containers": "",
        "analytes": "Nortriptyline"
    },
    {
        "code": "150124",
        "name": "O-Desmethyl-CIS-Tramadol",
        "containers": "",
        "analytes": "O-Desmethyl-CIS-Tramadol"
    },
    {
        "code": "160039",
        "name": "O-Desmethyl-CIS-Tramadol - Oral",
        "containers": "",
        "analytes": "O-Desmethyl-CIS-Tramadol- Oral"
    },
    {
        "code": "500020",
        "name": "Obstetric Profile",
        "containers": "",
        "analytes": "ABO Group Ref; Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chlamydia Trachomatis; Chloride; Cholesterol; Clarity; Color; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Ferritin; Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Hepatitis B Surface Ag; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Mucous; Neisseria Gonorrhoeae; Neutrophils Absolute; Neutrophils Percentage; Nitrite; pH, Urine; Platelets Count; Potassium; Protein, Urine; Rapid Plasma Reagin (RPR) - Qualitative; RDW; Red Blood Cell, Urine; Red Blood Cells; Rubella Ab - IgG; Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Total Protein; Triglycerides; Triiodothyronine (T3); Urobilinogen; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "130013",
        "name": "Occult Blood",
        "containers": "",
        "analytes": "Occult Blood"
    },
    {
        "code": "170261",
        "name": "Occult Blood, Fecal, Immunoassay Ref",
        "containers": "",
        "analytes": "Occult Blood, Fecal, Immunoassay Ref"
    },
    {
        "code": "150159",
        "name": "Opiate - EIA",
        "containers": "",
        "analytes": "Opiate - EIA"
    },
    {
        "code": "560007",
        "name": "Opiate - Oral",
        "containers": "",
        "analytes": "Codeine - Oral; Hydrocodone - Oral; Hydromorphone - Oral; Morphine - Oral; Norhydrocodone - Oral"
    },
    {
        "code": "150168",
        "name": "Opiate- Actual",
        "containers": "",
        "analytes": "Opiate - EIA"
    },
    {
        "code": "550009",
        "name": "Opiates",
        "containers": "",
        "analytes": "Codeine; Dihydrocodeine; Hydrocodone; Hydromorphone; Morphine; Norhydrocodone"
    },
    {
        "code": "560013",
        "name": "Opioid Antagonist - Oral",
        "containers": "",
        "analytes": "Buprenorphine - Oral; Naloxone - Oral; Naltrexone - Oral; Norbuprenorphine - Oral"
    },
    {
        "code": "550023",
        "name": "Opioid Antagonists",
        "containers": "",
        "analytes": "Buprenorphine; Naloxone; Naltrexone; Norbuprenorphine"
    },
    {
        "code": "550011",
        "name": "Opioids",
        "containers": "",
        "analytes": "Desmethyltapentadol; EDDP; Meperidine; Methadone; Normeperidine; Norpropoxyphene; O-Desmethyl-CIS-Tramadol; Propoxyphene; Tramadol"
    },
    {
        "code": "560010",
        "name": "Opioids - Oral",
        "containers": "",
        "analytes": "EDDP - Oral; Meperidine - Oral; Methadone - Oral; Normeperidine - Oral; O-Desmethyl-CIS-Tramadol- Oral; Propoxyphene - Oral; Tapentadol - Oral; Tramadol - Oral"
    },
    {
        "code": "170341",
        "name": "Orange, Allergen",
        "containers": "",
        "analytes": "Orange, Allergen Ref"
    },
    {
        "code": "140099",
        "name": "Orthopox",
        "containers": "",
        "analytes": "Orthopox"
    },
    {
        "code": "170232",
        "name": "Osmolality, Blood",
        "containers": "",
        "analytes": "Osmolality, Blood Ref"
    },
    {
        "code": "170154",
        "name": "Osmolality, Urine",
        "containers": "",
        "analytes": "Osmolality, Urine Ref"
    },
    {
        "code": "170228",
        "name": "Ova and Parasites Examination",
        "containers": "",
        "analytes": "Ova and Parasites Examination Ref"
    },
    {
        "code": "170325",
        "name": "Oxalate-Creatinine Ratio, Urine",
        "containers": "",
        "analytes": "Creatinine, Urine Ref; Oxalate-Creatinine Ratio, Urine Ref; Oxalates, Urine Ref"
    },
    {
        "code": "170155",
        "name": "Oxalic Acid w/o Creat 24H Ur",
        "containers": "",
        "analytes": "Oxalic Acid w/o Creat 24H Ur Ref"
    },
    {
        "code": "150086",
        "name": "Oxazepam",
        "containers": "",
        "analytes": "Oxazepam"
    },
    {
        "code": "160040",
        "name": "Oxazepam - Oral",
        "containers": "",
        "analytes": "Oxazepam - Oral"
    },
    {
        "code": "170234",
        "name": "Oxcarbazepine",
        "containers": "",
        "analytes": "Oxcarbazepine Ref"
    },
    {
        "code": "150184",
        "name": "Oxycodone",
        "containers": "",
        "analytes": "Oxycodone - Cup"
    },
    {
        "code": "150087",
        "name": "Oxycodone",
        "containers": "",
        "analytes": "Oxycodone"
    },
    {
        "code": "150160",
        "name": "Oxycodone - EIA",
        "containers": "",
        "analytes": "Oxycodone - EIA"
    },
    {
        "code": "160041",
        "name": "Oxycodone - Oral",
        "containers": "",
        "analytes": "Oxycodone - Oral"
    },
    {
        "code": "560017",
        "name": "Oxycodone - Oral Panel",
        "containers": "",
        "analytes": "Noroxycodone - Oral; Oxycodone - Oral; Oxymorphone - Oral"
    },
    {
        "code": "550025",
        "name": "Oxycodone Panel",
        "containers": "",
        "analytes": "Noroxycodone; Oxycodone; Oxymorphone"
    },
    {
        "code": "150088",
        "name": "Oxymorphone",
        "containers": "",
        "analytes": "Oxymorphone"
    },
    {
        "code": "160042",
        "name": "Oxymorphone - Oral",
        "containers": "",
        "analytes": "Oxymorphone - Oral"
    },
    {
        "code": "170312",
        "name": "Pancreatic Elastase, Fecal",
        "containers": "",
        "analytes": "Pancreatic Elastase, Fecal Ref"
    },
    {
        "code": "140052",
        "name": "Parainfluenza 1",
        "containers": "",
        "analytes": "Parainfluenza 1"
    },
    {
        "code": "140053",
        "name": "Parainfluenza 2",
        "containers": "",
        "analytes": "Parainfluenza 2"
    },
    {
        "code": "140054",
        "name": "Parainfluenza 3",
        "containers": "",
        "analytes": "Parainfluenza 3"
    },
    {
        "code": "140055",
        "name": "Parainfluenza 4",
        "containers": "",
        "analytes": "Parainfluenza 4"
    },
    {
        "code": "170274",
        "name": "Parasite Examination",
        "containers": "",
        "analytes": "Parasite Examination Ref"
    },
    {
        "code": "170359",
        "name": "Parasite Identification - Worm",
        "containers": "",
        "analytes": "Parasite Identification - Worm Ref"
    },
    {
        "code": "540024",
        "name": "Parasitic Agents - GI",
        "containers": "",
        "analytes": "Cryptosporidium Spp.; Cyclospora Cayetanensis; Entamoeba Histolytica; Giardia Lamblia"
    },
    {
        "code": "120009",
        "name": "Parathyroid Hormone (PTH)",
        "containers": "green_tube",
        "analytes": "Parathyroid Hormone (PTH)"
    },
    {
        "code": "150089",
        "name": "Paroxetine",
        "containers": "",
        "analytes": "Paroxetine"
    },
    {
        "code": "110006",
        "name": "Partial Thromboplastin Time (PTT)",
        "containers": "",
        "analytes": "Partial Thromboplastin Time (PTT)"
    },
    {
        "code": "170156",
        "name": "Parvo B19 IgG/IgM",
        "containers": "",
        "analytes": "Parvo B19 IgG/IgM Ref"
    },
    {
        "code": "550021",
        "name": "PCP",
        "containers": "",
        "analytes": "Phencyclidine (PCP)"
    },
    {
        "code": "170342",
        "name": "Peach, Allergen",
        "containers": "",
        "analytes": "Peach, Allergen Ref"
    },
    {
        "code": "150091",
        "name": "Pentazocine",
        "containers": "",
        "analytes": "Pentazocine"
    },
    {
        "code": "160054",
        "name": "Pentazocine - Oral",
        "containers": "",
        "analytes": "Pentazocine"
    },
    {
        "code": "150171",
        "name": "pH",
        "containers": "",
        "analytes": "pH"
    },
    {
        "code": "150185",
        "name": "Phencyclidine (PCP)",
        "containers": "",
        "analytes": "Phencyclidine (PCP) - Cup"
    },
    {
        "code": "150090",
        "name": "Phencyclidine (PCP)",
        "containers": "",
        "analytes": "Phencyclidine (PCP)"
    },
    {
        "code": "150161",
        "name": "Phencyclidine (PCP) - EIA",
        "containers": "",
        "analytes": "Phencyclidine (PCP) - EIA"
    },
    {
        "code": "560014",
        "name": "Phencyclidine (PCP) - Oral",
        "containers": "",
        "analytes": "Phencyclidine (PCP) - Oral"
    },
    {
        "code": "160043",
        "name": "Phencyclidine (PCP) - Oral",
        "containers": "",
        "analytes": "Phencyclidine (PCP) - Oral"
    },
    {
        "code": "150092",
        "name": "Phenobarbital",
        "containers": "",
        "analytes": "Phenobarbital"
    },
    {
        "code": "160044",
        "name": "Phenobarbital - Oral",
        "containers": "",
        "analytes": "Phenobarbital - Oral"
    },
    {
        "code": "100039",
        "name": "Phenobarbital Serum",
        "containers": "red_tube",
        "analytes": "Phenobarbital Serum Ref"
    },
    {
        "code": "150093",
        "name": "Phentermine",
        "containers": "",
        "analytes": "Phentermine"
    },
    {
        "code": "170157",
        "name": "Phenytoin",
        "containers": "",
        "analytes": "Phenytoin Ref"
    },
    {
        "code": "170256",
        "name": "Phenytoin, Free",
        "containers": "",
        "analytes": "Phenytoin, Free Ref"
    },
    {
        "code": "170158",
        "name": "Phosphate w/o Creat 24H Ur",
        "containers": "",
        "analytes": "Phosphate w/o Creat 24H Ur Ref"
    },
    {
        "code": "100041",
        "name": "Phosphorus",
        "containers": "",
        "analytes": "Phosphorus"
    },
    {
        "code": "170329",
        "name": "Pineapple, Allergen",
        "containers": "",
        "analytes": "Pineapple, Allergen Ref"
    },
    {
        "code": "110003",
        "name": "Platelets Count",
        "containers": "",
        "analytes": "Platelets Count"
    },
    {
        "code": "140138",
        "name": "Plesiomonas Shigelloides",
        "containers": "",
        "analytes": "Plesiomonas Shigelloides"
    },
    {
        "code": "170383",
        "name": "Pork, Allergen",
        "containers": "",
        "analytes": "Pork, Allergen Ref"
    },
    {
        "code": "100042",
        "name": "Potassium",
        "containers": "tiger_and_gold_tube",
        "analytes": "Potassium"
    },
    {
        "code": "170159",
        "name": "Potassium w/o Creat 24H Ur",
        "containers": "",
        "analytes": "Potassium w/o Creat 24H Ur Ref"
    },
    {
        "code": "170374",
        "name": "Potassium, Urine",
        "containers": "",
        "analytes": "Potassium, Urine Ref"
    },
    {
        "code": "170160",
        "name": "Prealbumin",
        "containers": "",
        "analytes": "Prealbumin Ref"
    },
    {
        "code": "150094",
        "name": "Pregabalin",
        "containers": "",
        "analytes": "Pregabalin"
    },
    {
        "code": "160045",
        "name": "Pregabalin - Oral",
        "containers": "",
        "analytes": "Pregabalin - Oral"
    },
    {
        "code": "170317",
        "name": "Pregnenolone, MS",
        "containers": "",
        "analytes": "Pregnenolone, MS Ref"
    },
    {
        "code": "500019",
        "name": "Prenatal Profile",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; C-Reactive Protein, High Sensitivity (CRP-HS); Calcium; Carbon Dioxide; Chlamydia Trachomatis; Chloride; Cholesterol; Clarity; Color; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Ferritin; Gamma-Glutamyl Transferase (GGT); Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; HCG, Total, Quantitative; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Hepatitis A Ab IgM; Hepatitis A Ab Total (IgG & IgM); Hepatitis B Core Ab Total; Hepatitis B Core Ab, IgM; Hepatitis B Surface Ab; Hepatitis B Surface Ag; Hepatitis C Virus Ab; HIV Combo Ag/Ab; Iron Total; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Mucous; Neisseria Gonorrhoeae; Neutrophils Absolute; Neutrophils Percentage; Nitrite; pH, Urine; Platelets Count; Potassium; Protein, Urine; Rapid Plasma Reagin (RPR) - Qualitative; RDW; Red Blood Cell, Urine; Red Blood Cells; Rubella Ab - IgG; Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Total Iron Binding Capacity (TIBC); Total Protein; Transferrin; Triglycerides; Triiodothyronine (T3); Urobilinogen; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "170297",
        "name": "Primidone, Serum or Plasma",
        "containers": "",
        "analytes": "Primidone, Serum or Plasma Ref"
    },
    {
        "code": "170161",
        "name": "ProBNP",
        "containers": "",
        "analytes": "ProBNP Ref"
    },
    {
        "code": "170162",
        "name": "Procalcitonin",
        "containers": "",
        "analytes": "Procalcitonin Ref"
    },
    {
        "code": "120010",
        "name": "Progesterone",
        "containers": "tiger_and_gold_tube",
        "analytes": "Progesterone"
    },
    {
        "code": "120011",
        "name": "Prolactin",
        "containers": "tiger_and_gold_tube",
        "analytes": "Prolactin"
    },
    {
        "code": "150096",
        "name": "Propoxyphene",
        "containers": "",
        "analytes": "Propoxyphene"
    },
    {
        "code": "160046",
        "name": "Propoxyphene - Oral",
        "containers": "",
        "analytes": "Propoxyphene - Oral"
    },
    {
        "code": "120012",
        "name": "Prostate Specific Ag (PSA), Free",
        "containers": "",
        "analytes": "Prostate Specific Ag (PSA), Free"
    },
    {
        "code": "120013",
        "name": "Prostate Specific Ag (PSA), Total",
        "containers": "",
        "analytes": "Prostate Specific Ag (PSA), Total"
    },
    {
        "code": "170337",
        "name": "Prostate-specific Antigen (PSA), Ultrasensitive",
        "containers": "",
        "analytes": "Prostate-specific Antigen (PSA), Ultrasensitive Ref"
    },
    {
        "code": "170163",
        "name": "Prot Elec+K/L Chains",
        "containers": "",
        "analytes": "Prot Elec+K/L Chains Ref"
    },
    {
        "code": "170377",
        "name": "Protein C, Functional",
        "containers": "",
        "analytes": "Protein C, Functional Ref"
    },
    {
        "code": "170224",
        "name": "Protein Electrophoresis, Serum",
        "containers": "tiger_and_gold_tube",
        "analytes": "Protein Electrophoresis, Serum Ref"
    },
    {
        "code": "170164",
        "name": "Protein Electrophoresis, w/total Protein w/RF Ur",
        "containers": "",
        "analytes": "Protein Electrophoresis, w/total Protein w/RF Ur Ref"
    },
    {
        "code": "170247",
        "name": "Protein S Antigen",
        "containers": "",
        "analytes": "Protein S Antigen Ref"
    },
    {
        "code": "170165",
        "name": "Protein, Total and Protein Electrophoresis",
        "containers": "",
        "analytes": "Protein, Total and Protein Electrophoresis Ref"
    },
    {
        "code": "170166",
        "name": "Protein, Total and Protein Electrophoresis w/IFE Serum",
        "containers": "",
        "analytes": "Protein, Total and Protein Electrophoresis w/IFE Serum Ref"
    },
    {
        "code": "170167",
        "name": "Protein, Total and Protein Electrophoresis w/RF, Serum",
        "containers": "",
        "analytes": "Protein, Total and Protein Electrophoresis w/RF, Serum Ref"
    },
    {
        "code": "170384",
        "name": "Protein, Total, Quantitative, 24-Hour Urine",
        "containers": "",
        "analytes": "Protein, Total, Quantitative, 24-Hour Urine Ref"
    },
    {
        "code": "140088",
        "name": "Proteus Mirabilis",
        "containers": "",
        "analytes": "Proteus Mirabilis"
    },
    {
        "code": "140180",
        "name": "Proteus spp.",
        "containers": "",
        "analytes": "Proteus spp."
    },
    {
        "code": "140067",
        "name": "Proteus spp.",
        "containers": "",
        "analytes": "Proteus spp."
    },
    {
        "code": "140089",
        "name": "Proteus Vulgaris",
        "containers": "",
        "analytes": "Proteus Vulgaris"
    },
    {
        "code": "140181",
        "name": "Providencia stuartii",
        "containers": "",
        "analytes": "Providencia Stuartii"
    },
    {
        "code": "140090",
        "name": "Providencia Stuartii",
        "containers": "",
        "analytes": "Providencia Stuartii"
    },
    {
        "code": "140182",
        "name": "Pseudomonas aeruginosa",
        "containers": "",
        "analytes": "Pseudomonas Aeruginosa"
    },
    {
        "code": "140068",
        "name": "Pseudomonas Aeruginosa",
        "containers": "",
        "analytes": "Pseudomonas Aeruginosa"
    },
    {
        "code": "510003",
        "name": "PT/INR",
        "containers": "light_blue_tube",
        "analytes": "International Normalized Ratio (INR); Prothrombin Time (PT)"
    },
    {
        "code": "170169",
        "name": "PTH, Intact & Calcium",
        "containers": "",
        "analytes": "PTH, Intact & Calcium Ref"
    },
    {
        "code": "170170",
        "name": "PTH, Intact w/o Cal",
        "containers": "",
        "analytes": "PTH, Intact w/o Cal Ref"
    },
    {
        "code": "120056",
        "name": "QuantiFERON-TB Gold",
        "containers": "",
        "analytes": "QuantiFERON-TB Gold"
    },
    {
        "code": "150098",
        "name": "Quetiapine",
        "containers": "",
        "analytes": "Quetiapine"
    },
    {
        "code": "120062",
        "name": "Rapid Plasma Reagin (RPR) - Qualitative",
        "containers": "",
        "analytes": "Rapid Plasma Reagin (RPR) - Qualitative"
    },
    {
        "code": "500034",
        "name": "Renal Function Panel",
        "containers": "",
        "analytes": "Albumin; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chloride; Creatinine Serum; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Phosphorus; Potassium; Sodium"
    },
    {
        "code": "170171",
        "name": "Respiratory Allergy Profile Region Viii",
        "containers": "",
        "analytes": "Respiratory Allergy Profile Region Viii Ref"
    },
    {
        "code": "540005",
        "name": "Respiratory RT-PCR 24 Panel",
        "containers": "vtm",
        "analytes": "Adenovirus; Bordetella Pertussis; Chlamydophila Pneumoniae; Haemophilus Influenzae; Human Bocavirus; Human Corona 229E; Human Corona HKU1; Human Corona NL63; Human Corona OC43; Human Enterovirus/Human Rhinovirus; Human Metapneumoviruses; Human Parechovirus; Influenza A; Influenza B; Legionella pneumophila; Mycoplasma pneumoniae; Parainfluenza 1; Parainfluenza 2; Parainfluenza 3; Parainfluenza 4; Respiratory Syncytial Virus A/B; SARS-CoV-2 by PCR; Streptococcus pneumoniae; Streptococcus Pyogenes (Group A)"
    },
    {
        "code": "540022",
        "name": "Respiratory RT-PCR 24 Panel w/Antibiotic Resistance",
        "containers": "vtm",
        "analytes": "Adenovirus; Bordetella Pertussis; Chlamydophila Pneumoniae; CTX-M ESBL - Beta Lactamase; Fluoroquinolone (Qnr); Haemophilus Influenzae; Human Bocavirus; Human Corona 229E; Human Corona HKU1; Human Corona NL63; Human Corona OC43; Human Enterovirus/Human Rhinovirus; Human Metapneumoviruses; Human Parechovirus; IMP - Carbapenem; Influenza A; Influenza B; KPC - Carbapenem; Legionella pneumophila; Methicillin (mecA+mecC); Mycoplasma pneumoniae; NDM - Carbapenem; OXA - 48 - Carbapenem; Parainfluenza 1; Parainfluenza 2; Parainfluenza 3; Parainfluenza 4; Respiratory Syncytial Virus A/B; SARS-CoV-2 by PCR; Streptococcus pneumoniae; Streptococcus Pyogenes (Group A); Sulfonamide (Sul); Trimethoprim (dfrA); Van A - Vancomycin; Van B - Vancomycin; VIM - Carbapenem"
    },
    {
        "code": "140056",
        "name": "Respiratory Syncytial Virus A/B",
        "containers": "",
        "analytes": "Respiratory Syncytial Virus A/B"
    },
    {
        "code": "110011",
        "name": "Retic Count",
        "containers": "",
        "analytes": "Reticulocyte Count"
    },
    {
        "code": "170172",
        "name": "Rh Type",
        "containers": "",
        "analytes": "Rh Type Ref"
    },
    {
        "code": "120043",
        "name": "Rheumatoid Factor",
        "containers": "tiger_and_gold_tube",
        "analytes": "Rheumatoid Factor"
    },
    {
        "code": "170173",
        "name": "Rheumatoid Factor Quantitative",
        "containers": "",
        "analytes": "Rheumatoid Factor Ref"
    },
    {
        "code": "500013",
        "name": "Rheumatoid Panel I Bundle",
        "containers": "",
        "analytes": "Antistreptolysin O (ASO) Qualitative; C-Reactive Protein, High Sensitivity (CRP-HS); Rheumatoid Factor; Sedimentation Rate, Westergren (ESR); Uric Acid"
    },
    {
        "code": "500028",
        "name": "Rheumatoid Panel II",
        "containers": "",
        "analytes": "Antistreptolysin O (ASO) Qualitative; Complement C3 Ref; Immunoglobulin A (IgA) Ref; Immunoglobulin G (IgG) Ref; Immunoglobulin M (IgM) Ref; Rheumatoid Factor; Uric Acid"
    },
    {
        "code": "120027",
        "name": "RIBO-P",
        "containers": "",
        "analytes": "RIBO-P"
    },
    {
        "code": "150100",
        "name": "Ritalinic Acid",
        "containers": "",
        "analytes": "Ritalinic Acid"
    },
    {
        "code": "160047",
        "name": "Ritalinic Acid - Oral",
        "containers": "",
        "analytes": "Ritalinic Acid - Oral"
    },
    {
        "code": "120033",
        "name": "RNP Abs",
        "containers": "",
        "analytes": "RNP Abs"
    },
    {
        "code": "120032",
        "name": "RNP/SM Abs",
        "containers": "",
        "analytes": "RNP/SM Abs"
    },
    {
        "code": "140150",
        "name": "Rotavirus (A)",
        "containers": "",
        "analytes": "Rotavirus (A)"
    },
    {
        "code": "170318",
        "name": "RPR Confirmation",
        "containers": "",
        "analytes": "RPR Ref; RPR, Quant Ref; Treponema pallidum Abs Ref"
    },
    {
        "code": "120058",
        "name": "Rubella Ab - IgG",
        "containers": "tiger_and_gold_tube",
        "analytes": "Rubella Ab - IgG"
    },
    {
        "code": "170391",
        "name": "Rubella Antibodies, IgM",
        "containers": "",
        "analytes": "Rubella Antibodies, IgM Ref"
    },
    {
        "code": "170363",
        "name": "Salivary Cortisol (Four Specimens)",
        "containers": "",
        "analytes": "Salivary Cortisol (Four Specimens) Ref"
    },
    {
        "code": "170324",
        "name": "Salmonella Antibodies, EIA",
        "containers": "",
        "analytes": "Salmonella Antibodies, EIA Ref"
    },
    {
        "code": "140133",
        "name": "Salmonella Spp.",
        "containers": "",
        "analytes": "Salmonella Spp"
    },
    {
        "code": "140147",
        "name": "Sapovirus (GI/GII/GIV/GV)",
        "containers": "",
        "analytes": "Sapovirus (GI/GII/GIV/GV)"
    },
    {
        "code": "140057",
        "name": "SARS-CoV-2 by PCR",
        "containers": "",
        "analytes": "SARS-CoV-2 by PCR"
    },
    {
        "code": "120014",
        "name": "SARS-CoV-2 IgG",
        "containers": "",
        "analytes": "SARS-CoV-2 IgG"
    },
    {
        "code": "120015",
        "name": "SARS-CoV-2 IgM",
        "containers": "",
        "analytes": "SARS-CoV-2 IgM"
    },
    {
        "code": "500018",
        "name": "School Physical Profile",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chloride; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Hematocrit; Hemoglobin; Iron Total; Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Neutrophils Absolute; Neutrophils Percentage; Platelets Count; Potassium; RDW; Red Blood Cells; Sodium; Total Iron Binding Capacity (TIBC); Total Protein; Transferrin; White Blood Cells"
    },
    {
        "code": "150101",
        "name": "Secobarbital",
        "containers": "",
        "analytes": "Secobarbital"
    },
    {
        "code": "550010",
        "name": "Sedative Hypnotics",
        "containers": "",
        "analytes": "Zaleplon; Zolpidem"
    },
    {
        "code": "110010",
        "name": "Sedimentation Rate, Westergren (ESR)",
        "containers": "",
        "analytes": "Sedimentation Rate, Westergren (ESR)"
    },
    {
        "code": "170365",
        "name": "Selenium, Serum or Plasma",
        "containers": "",
        "analytes": "Selenium, Serum or Plasma Ref"
    },
    {
        "code": "140183",
        "name": "Serratia marcescens",
        "containers": "",
        "analytes": "Serratia Marcescens"
    },
    {
        "code": "140069",
        "name": "Serratia Marcescens",
        "containers": "",
        "analytes": "Serratia Marcescens"
    },
    {
        "code": "150102",
        "name": "Sertraline",
        "containers": "",
        "analytes": "Sertraline"
    },
    {
        "code": "140141",
        "name": "Shiga Toxin Producing E. Coli (STEC)",
        "containers": "",
        "analytes": "Shiga Toxin Producing E. Coli (STEC)"
    },
    {
        "code": "140139",
        "name": "Shigella / Enteroinvasive E. Coli (EIEC)",
        "containers": "",
        "analytes": "Shigella / Enteroinvasive E. Coli (EIEC)"
    },
    {
        "code": "110012",
        "name": "Sickle Cell Screen",
        "containers": "",
        "analytes": "Sickle Cell Screen"
    },
    {
        "code": "170233",
        "name": "Sjogren Antibodies",
        "containers": "",
        "analytes": "Sjogren Antibodies Ref"
    },
    {
        "code": "120031",
        "name": "Smith Abs",
        "containers": "",
        "analytes": "Smith Abs"
    },
    {
        "code": "100044",
        "name": "Sodium",
        "containers": "tiger_and_gold_tube",
        "analytes": "Sodium"
    },
    {
        "code": "170174",
        "name": "Sodium w/o Creat 24H Ur",
        "containers": "",
        "analytes": "Sodium w/o Creat 24H Ur Ref"
    },
    {
        "code": "170373",
        "name": "Sodium, Urine",
        "containers": "",
        "analytes": "Sodium, Urine Ref"
    },
    {
        "code": "150172",
        "name": "Specific Gravity",
        "containers": "",
        "analytes": "Specific Gravity"
    },
    {
        "code": "120028",
        "name": "SSA Abs",
        "containers": "",
        "analytes": "SSA Abs"
    },
    {
        "code": "140184",
        "name": "Staphylococcus aureus",
        "containers": "",
        "analytes": "Staphylococcus Aureus"
    },
    {
        "code": "140108",
        "name": "Staphylococcus Aureus",
        "containers": "",
        "analytes": "Staphylococcus Aureus"
    },
    {
        "code": "140070",
        "name": "Staphylococcus Aureus",
        "containers": "",
        "analytes": "Staphylococcus Aureus"
    },
    {
        "code": "140185",
        "name": "Staphylococcus epidermidis",
        "containers": "",
        "analytes": "Staphylococcus epidermidis"
    },
    {
        "code": "140186",
        "name": "Staphylococcus saprophyticus",
        "containers": "",
        "analytes": "Staphylococcus Saprophyticus"
    },
    {
        "code": "140091",
        "name": "Staphylococcus Saprophyticus",
        "containers": "",
        "analytes": "Staphylococcus Saprophyticus"
    },
    {
        "code": "500030",
        "name": "STD Profile",
        "containers": "",
        "analytes": "Chlamydia Trachomatis; Hepatitis A Ab IgM; Hepatitis B Core Ab, IgM; Hepatitis B Surface Ag; Hepatitis C Virus Ab; Herpes Simplex Virus (HSV) 1 Ab, IgG; Herpes Simplex Virus (HSV) 2 Ab, IgG; HIV Combo Ag/Ab; Neisseria Gonorrhoeae; Rapid Plasma Reagin (RPR) - Qualitative; Trichomonas Vaginalis"
    },
    {
        "code": "540014",
        "name": "STI qPCR Panel",
        "containers": "",
        "analytes": "Chlamydia Trachomatis; Gardnerella Vaginalis; Herpes Simplex Virus 1; Herpes Simplex Virus 2; Mycoplasma Genitalium; Mycoplasma Hominis; Neisseria Gonorrhoeae; Treponema Pallidum; Trichomonas Vaginalis; Ureaplasma Parvum/Urealyticum"
    },
    {
        "code": "540020",
        "name": "STI/Vaginal Infection EXPANDED w/Antibiotic Resistance",
        "containers": "",
        "analytes": "Atopobium Vaginae; BVAB-2 / Megasphaera 1 / Megasphaera 2; Candida Albicans; Candida Glabrata; Candida Species (Tropicalis, Parapsilosis & Dubliniensis); Chlamydia Trachomatis; CTX-M ESBL - Beta Lactamase; Fluoroquinolone (Qnr); Gardnerella Vaginalis; Herpes Simplex Virus 1; Herpes Simplex Virus 2; IMP - Carbapenem; KPC - Carbapenem; Methicillin (mecA+mecC); Mycoplasma Genitalium; Mycoplasma Hominis; NDM - Carbapenem; Neisseria Gonorrhoeae; OXA - 48 - Carbapenem; Sulfonamide (Sul); Treponema Pallidum; Trichomonas Vaginalis; Trimethoprim (dfrA); Ureaplasma Parvum/Urealyticum; Van A - Vancomycin; Van B - Vancomycin; VIM - Carbapenem"
    },
    {
        "code": "540015",
        "name": "STI/Vaginal Infection qPCR Expanded Pathogens",
        "containers": "",
        "analytes": "Atopobium Vaginae; BVAB-2 / Megasphaera 1 / Megasphaera 2; Candida Albicans; Candida Glabrata; Candida Species (Tropicalis, Parapsilosis & Dubliniensis); Chlamydia Trachomatis; Gardnerella Vaginalis; Herpes Simplex Virus 1; Herpes Simplex Virus 2; Mycoplasma Genitalium; Mycoplasma Hominis; Neisseria Gonorrhoeae; Treponema Pallidum; Trichomonas Vaginalis; Ureaplasma Parvum/Urealyticum"
    },
    {
        "code": "550015",
        "name": "Stimulants",
        "containers": "",
        "analytes": "Ritalinic Acid"
    },
    {
        "code": "560015",
        "name": "Stimulants - Oral",
        "containers": "",
        "analytes": "Methylphenidate - Oral; Ritalinic Acid - Oral"
    },
    {
        "code": "170229",
        "name": "Stool Culture",
        "containers": "stool_container",
        "analytes": "Stool Culture Ref"
    },
    {
        "code": "140187",
        "name": "Streptococcus agalactiae",
        "containers": "",
        "analytes": "Streptococcus Agalactiae"
    },
    {
        "code": "140071",
        "name": "Streptococcus Agalactiae",
        "containers": "",
        "analytes": "Streptococcus Agalactiae"
    },
    {
        "code": "130011",
        "name": "Streptococcus Group A Rapid",
        "containers": "",
        "analytes": "Streptococcus Group A Rapid"
    },
    {
        "code": "140058",
        "name": "Streptococcus pneumoniae",
        "containers": "",
        "analytes": "Streptococcus pneumoniae"
    },
    {
        "code": "140188",
        "name": "Streptococcus pyogenes",
        "containers": "",
        "analytes": "Streptococcus Pyogenes (Group A)"
    },
    {
        "code": "140059",
        "name": "Streptococcus Pyogenes (Group A)",
        "containers": "",
        "analytes": "Streptococcus Pyogenes (Group A)"
    },
    {
        "code": "140194",
        "name": "Sulfonamide (Sul)",
        "containers": "",
        "analytes": "Sulfonamide (Sul)"
    },
    {
        "code": "140128",
        "name": "Sulfonamide (Sul)",
        "containers": "",
        "analytes": "Sulfonamide (Sul)"
    },
    {
        "code": "140116",
        "name": "Sulfonamide (Sul)",
        "containers": "",
        "analytes": "Sulfonamide (Sul)"
    },
    {
        "code": "140095",
        "name": "Sulfonamide (Sul)",
        "containers": "",
        "analytes": "Sulfonamide (Sul)"
    },
    {
        "code": "550027",
        "name": "Synthetic Opioids",
        "containers": "",
        "analytes": "Buprenorphine; EDDP; Fentanyl; Meperidine; Methadone; Norbuprenorphine; Norfentanyl; Normeperidine; O-Desmethyl-CIS-Tramadol; Pentazocine; Propoxyphene; Tramadol"
    },
    {
        "code": "560019",
        "name": "Synthetic Opioids - Oral",
        "containers": "",
        "analytes": "Buprenorphine - Oral; EDDP - Oral; Fentanyl - Oral; Meperidine - Oral; Methadone - Oral; Norbuprenorphine - Oral; Norfentanyl - Oral; Normeperidine - Oral; O-Desmethyl-CIS-Tramadol- Oral; Propoxyphene - Oral; Tapentadol - Oral; Tramadol - Oral"
    },
    {
        "code": "550022",
        "name": "Synthetic THCs",
        "containers": "",
        "analytes": "JWH-018 4-Hydroxypentyl; JWH-073 3-Hydroxybutyl"
    },
    {
        "code": "170175",
        "name": "Syphilis Ab Cascade",
        "containers": "",
        "analytes": "Syphilis Ab Cascade Ref"
    },
    {
        "code": "120045",
        "name": "Systemic Lupus Erythematosus (SLE)",
        "containers": "",
        "analytes": "Systemic Lupus Erythematosus (SLE)"
    },
    {
        "code": "170176",
        "name": "T3 Reverse By LCMS",
        "containers": "",
        "analytes": "T3 Reverse By LCMS Ref"
    },
    {
        "code": "170177",
        "name": "T3 Uptake",
        "containers": "",
        "analytes": "T3 Uptake Ref"
    },
    {
        "code": "170178",
        "name": "Tacrolimus, LCMS",
        "containers": "",
        "analytes": "Tacrolimus, LCMS Ref"
    },
    {
        "code": "160048",
        "name": "Tapentadol - Oral",
        "containers": "",
        "analytes": "Tapentadol - Oral"
    },
    {
        "code": "150105",
        "name": "Temazepam",
        "containers": "",
        "analytes": "Temazepam"
    },
    {
        "code": "160049",
        "name": "Temazepam - Oral",
        "containers": "",
        "analytes": "Temazepam - Oral"
    },
    {
        "code": "170180",
        "name": "Testosterone, Free",
        "containers": "",
        "analytes": "Testosterone, Free Ref"
    },
    {
        "code": "120016",
        "name": "Testosterone, Total",
        "containers": "",
        "analytes": "Testosterone, Total"
    },
    {
        "code": "170394",
        "name": "Testosterone, Total, Mass Spectrometry",
        "containers": "",
        "analytes": "Testosterone, Total, Mass Spectrometry Ref"
    },
    {
        "code": "170181",
        "name": "Tetanus Antitoxoid",
        "containers": "",
        "analytes": "Tetanus Antitoxoid Ref"
    },
    {
        "code": "550018",
        "name": "THC",
        "containers": "",
        "analytes": "THC-COOH"
    },
    {
        "code": "560016",
        "name": "THC - Oral",
        "containers": "",
        "analytes": "THC-COOH  - Oral"
    },
    {
        "code": "150106",
        "name": "THC-COOH",
        "containers": "",
        "analytes": "THC-COOH"
    },
    {
        "code": "160052",
        "name": "THC-COOH - Oral",
        "containers": "",
        "analytes": "THC-COOH  - Oral"
    },
    {
        "code": "170386",
        "name": "Theophylline",
        "containers": "",
        "analytes": "Theophylline Ref"
    },
    {
        "code": "120037",
        "name": "Thyroglobulin Ab (Anti-Tg)",
        "containers": "",
        "analytes": "Thyroglobulin Ab (Anti-Tg)"
    },
    {
        "code": "500033",
        "name": "Thyroid Panel Bundle",
        "containers": "",
        "analytes": "Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Triiodothyronine (T3); Triiodothyronine (T3), Free"
    },
    {
        "code": "120036",
        "name": "Thyroid Peroxidase (TPO) Ab",
        "containers": "",
        "analytes": "Thyroid Peroxidase (TPO) Ab"
    },
    {
        "code": "120017",
        "name": "Thyroid Stimulating Hormone (TSH)",
        "containers": "tiger_and_gold_tube",
        "analytes": "Thyroid Stimulating Hormone (TSH)"
    },
    {
        "code": "500035",
        "name": "Thyroid/Obesity Panel Bundle",
        "containers": "",
        "analytes": "Alanine Aminotransferase (ALT); Albumin; Alkaline Phosphatase (ALP); Aspartate Aminotransferase (AST); Bacteria, Urine; Basophils Absolute; Basophils Percentage; Bilirubin, Total (T-Bili); Bilirubin, Urine; Blood; Blood Urea Nitrogen (BUN); BUN/Creatinine Ratio; Calcium; Carbon Dioxide; Chloride; Cholesterol; Clarity; Color; Cortisol; CPK Total; Creatinine Serum; Eosinophils Absolute; Eosinophils Percentage; Globulin; Glomerular Filtration Rate (Estimated for African American); Glomerular Filtration Rate (Estimated); Glucose; Glucose, Urine; HDL Cholesterol; HDL/Cholesterol Ratio; Hematocrit; Hemoglobin; Ketone; LDL Cholesterol(Calculated); Leukocytes; Lymphocytes Absolute; Lymphocytes Percentage; MCH; MCHC; MCV; Monocytes Absolute; Monocytes Percentage; Mucous; Neutrophils Absolute; Neutrophils Percentage; Nitrite; pH, Urine; Platelets Count; Potassium; Protein, Urine; RDW; Red Blood Cell, Urine; Red Blood Cells; Sedimentation Rate, Westergren (ESR); Sodium; Specific Gravity, Urine; Squamous Epithelial cell; Thyroid Stimulating Hormone (TSH); Thyroxine (T4); Thyroxine (T4), Free; Total Protein; Triglycerides; Triiodothyronine (T3); Urobilinogen; Vitamin D, 25-Hydroxy; VLDL Cholesterol; White Blood Cell Clump; White Blood Cell, Urine; White Blood Cells"
    },
    {
        "code": "120018",
        "name": "Thyroxine (T4)",
        "containers": "tiger_and_gold_tube",
        "analytes": "Thyroxine (T4)"
    },
    {
        "code": "120019",
        "name": "Thyroxine (T4), Free",
        "containers": "tiger_and_gold_tube",
        "analytes": "Thyroxine (T4), Free"
    },
    {
        "code": "170184",
        "name": "Tissue Transglutaminase (tTG) Ab, IgA",
        "containers": "",
        "analytes": "Tissue Transglutaminase (tTG) Ab, IgA Ref"
    },
    {
        "code": "170186",
        "name": "Tissue Transglutaminase (tTG) Ab, IgG",
        "containers": "",
        "analytes": "Tissue Transglutaminase (tTG) Ab, IgG Ref"
    },
    {
        "code": "170265",
        "name": "Topiramate, Serum or Plasma",
        "containers": "",
        "analytes": "Topiramate, Serum or Plasma Ref"
    },
    {
        "code": "500031",
        "name": "Total Iron Binding Capacity Panel",
        "containers": "",
        "analytes": "Iron Total; Total Iron Binding Capacity (TIBC); Transferrin"
    },
    {
        "code": "100046",
        "name": "Total Protein",
        "containers": "",
        "analytes": "Total Protein"
    },
    {
        "code": "100057",
        "name": "Total Protein, Ur",
        "containers": "",
        "analytes": "Total Protein, Ur"
    },
    {
        "code": "170187",
        "name": "Toxoplasma Ab IgG",
        "containers": "",
        "analytes": "Toxoplasma Ab IgG Ref"
    },
    {
        "code": "170188",
        "name": "TRAb (TSH Receptor Binding Ab)",
        "containers": "",
        "analytes": "TraTrab (Tsh Receptor Binding Ab) Ref"
    },
    {
        "code": "150108",
        "name": "Tramadol",
        "containers": "",
        "analytes": "Tramadol"
    },
    {
        "code": "160051",
        "name": "Tramadol - Oral",
        "containers": "",
        "analytes": "Tramadol - Oral"
    },
    {
        "code": "100047",
        "name": "Transferrin",
        "containers": "",
        "analytes": "Transferrin"
    },
    {
        "code": "100059",
        "name": "Transferrin Saturation",
        "containers": "",
        "analytes": "Iron Total; Total Iron Binding Capacity (TIBC); Transferrin Saturation"
    },
    {
        "code": "140077",
        "name": "Treponema Pallidum",
        "containers": "",
        "analytes": "Treponema Pallidum"
    },
    {
        "code": "140029",
        "name": "Trichomonas Vaginalis",
        "containers": "",
        "analytes": "Trichomonas Vaginalis"
    },
    {
        "code": "100048",
        "name": "Triglycerides",
        "containers": "",
        "analytes": "Triglycerides"
    },
    {
        "code": "120020",
        "name": "Triiodothyronine (T3)",
        "containers": "",
        "analytes": "Triiodothyronine (T3)"
    },
    {
        "code": "120021",
        "name": "Triiodothyronine (T3), Free",
        "containers": "",
        "analytes": "Triiodothyronine (T3), Free"
    },
    {
        "code": "140193",
        "name": "Trimethoprim (dfrA)",
        "containers": "",
        "analytes": "Trimethoprim (dfrA)"
    },
    {
        "code": "140129",
        "name": "Trimethoprim (dfrA)",
        "containers": "",
        "analytes": "Trimethoprim (dfrA)"
    },
    {
        "code": "140117",
        "name": "Trimethoprim (dfrA)",
        "containers": "",
        "analytes": "Trimethoprim (dfrA)"
    },
    {
        "code": "140096",
        "name": "Trimethoprim (dfrA)",
        "containers": "",
        "analytes": "Trimethoprim (dfrA)"
    },
    {
        "code": "170190",
        "name": "Troponin T",
        "containers": "",
        "analytes": "Troponin T Ref"
    },
    {
        "code": "170191",
        "name": "Tryptase Total",
        "containers": "",
        "analytes": "Tryptase Total Ref"
    },
    {
        "code": "170192",
        "name": "TSI (Thyroid Stimulating Immunoglobulin)",
        "containers": "",
        "analytes": "TSI (Thyroid Stimulating Immunoglobulin) Ref"
    },
    {
        "code": "550002",
        "name": "UDS Pre",
        "containers": "",
        "analytes": "Amphetamine - EIA; Barbiturates - EIA; Benzodiazepines - EIA; Cocaine - EIA; Methadone - EIA; Opiate - EIA; THC - EIA"
    },
    {
        "code": "550003",
        "name": "UDS Pre - Extended",
        "containers": "",
        "analytes": "Amphetamine - EIA; Barbiturates - EIA; Benzodiazepines - EIA; Buprenorphine - EIA; Cocaine - EIA; Methadone - EIA; Opiate - EIA; Oxycodone - EIA; Phencyclidine (PCP) - EIA; THC - EIA"
    },
    {
        "code": "550029",
        "name": "UDS Pre by Cup",
        "containers": "",
        "analytes": "Amphetamine - Cup; Barbiturates - Cup; Benzodiazepines - Cup; Buprenorphine - Cup; Cocaine - Cup; Marijuana (THC) - Cup; MDMA - Cup; Methadone (MTD) - Cup; Methamphetamine - Cup; Morphine - Cup; Oxycodone - Cup; Phencyclidine (PCP) - Cup"
    },
    {
        "code": "500003",
        "name": "UDS Semi-Quant",
        "containers": "",
        "analytes": "Amphetamine - EIA; Barbiturates - EIA; Benzodiazepines - EIA; Cocaine - EIA; Methadone - EIA; Opiate - EIA; THC - EIA"
    },
    {
        "code": "170331",
        "name": "Upper Respiratory Culture, Routine",
        "containers": "",
        "analytes": "Upper Respiratory Culture, Routine Ref"
    },
    {
        "code": "140204",
        "name": "Ureaplasma Parvum",
        "containers": "",
        "analytes": "Ureaplasma Parvum"
    },
    {
        "code": "140078",
        "name": "Ureaplasma Parvum/Urealyticum",
        "containers": "",
        "analytes": "Ureaplasma Parvum/Urealyticum"
    },
    {
        "code": "140079",
        "name": "Ureaplasma Urealyticum",
        "containers": "",
        "analytes": "Ureaplasma Urealyticum"
    },
    {
        "code": "100049",
        "name": "Uric Acid",
        "containers": "tiger_and_gold_tube",
        "analytes": "Uric Acid"
    },
    {
        "code": "170193",
        "name": "Uric Acid Ur",
        "containers": "",
        "analytes": "Uric Acid Ur Ref"
    },
    {
        "code": "170194",
        "name": "Uric Acid w/o Creat 24H Ur",
        "containers": "",
        "analytes": "Uric Acid w/o Creat 24H Ur Ref"
    },
    {
        "code": "500007",
        "name": "Urinalysis",
        "containers": "urine_collections",
        "analytes": "Bacteria, Urine; Bilirubin, Urine; Blood; Clarity; Color; Glucose, Urine; Ketone; Leukocytes; Mucous; Nitrite; pH, Urine; Protein, Urine; Red Blood Cell, Urine; Specific Gravity, Urine; Squamous Epithelial cell; Urobilinogen; White Blood Cell Clump; White Blood Cell, Urine"
    },
    {
        "code": "500043",
        "name": "Urinalysis with Reflex to Culture",
        "containers": "urine_collections",
        "analytes": "Bacteria, Urine; Bilirubin, Urine; Blood; Clarity; Color; Glucose, Urine; Ketone; Leukocytes; Mucous; Nitrite; pH, Urine; Protein, Urine; Red Blood Cell, Urine; Specific Gravity, Urine; Squamous Epithelial cell; Urobilinogen; White Blood Cell Clump; White Blood Cell, Urine"
    },
    {
        "code": "540017",
        "name": "Urinary Tract Infections qPCR Panel",
        "containers": "",
        "analytes": "Acinetobacter Baumannii; Aerococcus Urinae; Candida Albicans; Candida Auris; Candida Glabrata; Candida Krusei; Candida Parapsilosis; Candida Tropicalis; Citrobacter Freundii; Corynebacterium Urealyticum; Enterobacter Cloacae Complex; Enterococcus Faecalis; Enterococcus Faecium; Escherichia Coli; Klebsiella Aerogenes; Klebsiella Oxytoca; Klebsiella pneumoniae; Morganella Morganii; Proteus Mirabilis; Proteus Vulgaris; Providencia Stuartii; Pseudomonas Aeruginosa; Serratia Marcescens; Staphylococcus Aureus; Staphylococcus Saprophyticus; Streptococcus Agalactiae; Treponema Pallidum; Ureaplasma Parvum; Ureaplasma Parvum/Urealyticum; Ureaplasma Urealyticum"
    },
    {
        "code": "170392",
        "name": "Urine Culture, Routine",
        "containers": "",
        "analytes": "Urine Culture, Routine Ref"
    },
    {
        "code": "170268",
        "name": "Urine Cytology",
        "containers": "",
        "analytes": "Urine Cytology Ref"
    },
    {
        "code": "100055",
        "name": "Urine Protein/ Creatinine Ratio",
        "containers": "",
        "analytes": "Creatinine Ur; Total Protein, Ur; Urine Protein/ Creatinine Ratio"
    },
    {
        "code": "540016",
        "name": "UTI with Antibiotic Resistance qPCR Panel",
        "containers": "urine_cup_and_yellow_urine_tube",
        "analytes": "Acinetobacter Baumannii; Aerococcus Urinae; Candida Albicans; Candida Auris; Candida Glabrata; Candida Krusei; Candida Parapsilosis; Candida Tropicalis; Citrobacter Freundii; Corynebacterium Urealyticum; CTX-M ESBL - Beta Lactamase; Enterobacter Cloacae Complex; Enterococcus Faecalis; Enterococcus Faecium; Escherichia Coli; Fluoroquinolone (Qnr); IMP - Carbapenem; Klebsiella Aerogenes; Klebsiella Oxytoca; Klebsiella pneumoniae; KPC - Carbapenem; Methicillin (mecA+mecC); Morganella Morganii; NDM - Carbapenem; OXA - 48 - Carbapenem; Proteus Mirabilis; Proteus Vulgaris; Providencia Stuartii; Pseudomonas Aeruginosa; Serratia Marcescens; Staphylococcus Aureus; Staphylococcus Saprophyticus; Streptococcus Agalactiae; Sulfonamide (Sul); Treponema Pallidum; Trimethoprim (dfrA); Ureaplasma Parvum; Ureaplasma Parvum/Urealyticum; Ureaplasma Urealyticum; Van A - Vancomycin; Van B - Vancomycin; VIM - Carbapenem"
    },
    {
        "code": "540011",
        "name": "Vaginosis Panel by qPCR",
        "containers": "e_swab",
        "analytes": "Atopobium Vaginae; BVAB-2 / Megasphaera 1 / Megasphaera 2; Candida Albicans; Candida Glabrata; Candida Species (Tropicalis, Parapsilosis & Dubliniensis); Gardnerella Vaginalis"
    },
    {
        "code": "100050",
        "name": "Valproic Acid (Depakote), Total",
        "containers": "",
        "analytes": "Valproic Acid (Depakote), Total"
    },
    {
        "code": "170195",
        "name": "Valproic Acid, Free",
        "containers": "red_tube",
        "analytes": "Valproic Acid, Free Ref"
    },
    {
        "code": "170196",
        "name": "Vancomycin, Trough",
        "containers": "",
        "analytes": "Vancomycin, Trough Ref"
    },
    {
        "code": "140189",
        "name": "Varicella zoster Virus",
        "containers": "",
        "analytes": "Varicella Zoster Virus"
    },
    {
        "code": "170266",
        "name": "Varicella Zoster Virus (VZV) Ab, Quantitative, IgM",
        "containers": "",
        "analytes": "Varicella Zoster Virus (VZV) Ab, Quantitative, IgM Ref"
    },
    {
        "code": "120061",
        "name": "Varicella Zoster Virus(VZV) Ab - IgG",
        "containers": "",
        "analytes": "Varicella Zoster Virus(VZV) Ab - IgG"
    },
    {
        "code": "170272",
        "name": "Vectra",
        "containers": "",
        "analytes": "Vectra Ref"
    },
    {
        "code": "140136",
        "name": "Vibrio Cholerae",
        "containers": "",
        "analytes": "Vibrio Cholerae"
    },
    {
        "code": "140135",
        "name": "Vibrio Parahaemolyticus",
        "containers": "",
        "analytes": "Vibrio Parahaemolyticus"
    },
    {
        "code": "540007",
        "name": "Viral Respiratory Infection by qPCR",
        "containers": "",
        "analytes": "Adenovirus; Human Bocavirus; Human Enterovirus/Human Rhinovirus; Human Metapneumoviruses; Influenza A; Influenza B; Parainfluenza 1; Parainfluenza 2; Parainfluenza 3; Parainfluenza 4; Respiratory Syncytial Virus A/B; SARS-CoV-2 by PCR"
    },
    {
        "code": "540025",
        "name": "Viruses - GI",
        "containers": "",
        "analytes": "Adenovirus; Astrovirus; Norovirus(GI/GII); Rotavirus (A); Sapovirus (GI/GII/GIV/GV)"
    },
    {
        "code": "170197",
        "name": "Vitamin A (Retinol)",
        "containers": "",
        "analytes": "Vitamin A (Retinol) Ref"
    },
    {
        "code": "170199",
        "name": "Vitamin B1,Thiamine",
        "containers": "",
        "analytes": "Vitamin B1,Thiamine Ref"
    },
    {
        "code": "120022",
        "name": "Vitamin B12",
        "containers": "tiger_and_gold_tube",
        "analytes": "Vitamin B12"
    },
    {
        "code": "170242",
        "name": "Vitamin B2, Whole Blood",
        "containers": "",
        "analytes": "Vitamin B2, Whole Blood Ref"
    },
    {
        "code": "170237",
        "name": "Vitamin B3 (Niacin and Metabolite)",
        "containers": "",
        "analytes": "Nicotinamide (Vit B3) Ref; Nicotinic Acid (Vit B3) Ref"
    },
    {
        "code": "170200",
        "name": "Vitamin B6",
        "containers": "lavender_tube",
        "analytes": "Vitamin B6 Ref"
    },
    {
        "code": "170201",
        "name": "Vitamin C",
        "containers": "",
        "analytes": "Vitamin C Ref"
    },
    {
        "code": "120023",
        "name": "Vitamin D, 25-Hydroxy",
        "containers": "tiger_and_gold_tube",
        "analytes": "Vitamin D, 25-Hydroxy"
    },
    {
        "code": "170203",
        "name": "Vitamin E",
        "containers": "",
        "analytes": "Vitamin E Ref"
    },
    {
        "code": "170204",
        "name": "Vitamin K",
        "containers": "",
        "analytes": "Vitamin K Ref"
    },
    {
        "code": "100051",
        "name": "VLDL Cholesterol",
        "containers": "",
        "analytes": "VLDL Cholesterol"
    },
    {
        "code": "170205",
        "name": "Von Willebrand Ag",
        "containers": "",
        "analytes": "Von Willebrand Ag Ref"
    },
    {
        "code": "170243",
        "name": "VZV Real Time PCR",
        "containers": "",
        "analytes": "VZV Real Time PCR Ref"
    },
    {
        "code": "170206",
        "name": "West Nile Virus (WNV) Ab",
        "containers": "",
        "analytes": "West Nile Virus (WNV) Ab Ref"
    },
    {
        "code": "110004",
        "name": "White Blood Cells",
        "containers": "",
        "analytes": "White Blood Cells"
    },
    {
        "code": "170238",
        "name": "White Blood Cells (WBC), Stool",
        "containers": "",
        "analytes": "White Blood Cells (WBC), Stool Ref"
    },
    {
        "code": "170357",
        "name": "Whole Exome Sequencing - TRIO",
        "containers": "",
        "analytes": "Whole Exome Sequencing - TRIO Ref"
    },
    {
        "code": "540034",
        "name": "Wound Panel",
        "containers": "e_swab",
        "analytes": "Acinetobacter Baumannii; Burkholderia spp; Citrobacter Freundii; Clostridium novyi; Clostridium septicum; Cutibacterium spp; Enterobacter Cloacae Complex; Enterococcus faecalis/faecium; Escherichia Coli; Herpes Simplex Virus 1; Herpes Simplex Virus 2; Klebsiella Aerogenes; Klebsiella Oxytoca; Klebsiella pneumoniae; Morganella Morganii; Mycobacterium abscessus; Mycobacterium avium Complex; Mycobacterium chelonae; Mycobacterium fortuitum; Mycobacterium ulcerans; Proteus spp.; Providencia Stuartii; Pseudomonas Aeruginosa; Serratia Marcescens; Staphylococcus Aureus; Staphylococcus epidermidis; Staphylococcus Saprophyticus; Streptococcus Agalactiae; Streptococcus Pyogenes (Group A); Varicella Zoster Virus"
    },
    {
        "code": "540036",
        "name": "Wound Panels w/ Antibiotic Resistance",
        "containers": "e_swab",
        "analytes": "Acinetobacter Baumannii; Burkholderia spp; Citrobacter Freundii; Clostridium novyi; Clostridium septicum; CTX-M ESBL - Beta Lactamase; Cutibacterium spp; Enterobacter Cloacae Complex; Enterococcus faecalis/faecium; Escherichia Coli; Fluoroquinolone (Qnr); Herpes Simplex Virus 1; Herpes Simplex Virus 2; IMP - Carbapenem; Klebsiella Aerogenes; Klebsiella Oxytoca; Klebsiella pneumoniae; KPC - Carbapenem; Methicillin (mecA+mecC); Morganella Morganii; Mycobacterium abscessus; Mycobacterium avium Complex; Mycobacterium chelonae; Mycobacterium fortuitum; Mycobacterium ulcerans; NDM - Carbapenem; OXA - 48 - Carbapenem; Proteus spp.; Providencia Stuartii; Pseudomonas Aeruginosa; Serratia Marcescens; Staphylococcus Aureus; Staphylococcus epidermidis; Staphylococcus Saprophyticus; Streptococcus Agalactiae; Streptococcus Pyogenes (Group A); Sulfonamide (Sul); Trimethoprim (dfrA); Van A - Vancomycin; Van B - Vancomycin; Varicella Zoster Virus; VIM - Carbapenem"
    },
    {
        "code": "140137",
        "name": "Yersinia Enterocolitica",
        "containers": "",
        "analytes": "Yersinia Enterocolitica"
    },
    {
        "code": "150111",
        "name": "Zaleplon",
        "containers": "",
        "analytes": "Zaleplon"
    },
    {
        "code": "170207",
        "name": "Zinc, Serum or Plasma",
        "containers": "",
        "analytes": "Zinc, Serum or Plasma Ref"
    },
    {
        "code": "150112",
        "name": "Zolpidem",
        "containers": "",
        "analytes": "Zolpidem"
    }
]

export { Panels }