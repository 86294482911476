// import logo from './logo.svg';
import './App.css';
import { useState } from 'react';

import { Panels } from "./Panels";
import { FilterablePanelsTable } from "./FilterablePanelsTable"
import Modal from "./Modal";
import { CollectionGuide } from "./CollectionGuide"

function App() {
  let [isOpen, setIsOpen] = useState(false)
  let [panel, setPanel] = useState(null)
  let [selectedCard, setSelectedCard] = useState(null)

  const setSelectedCardAndOpenModal = (panelCode) => {
    setSelectedCard(panelCode)
    setIsOpen(true)
    setPanel(Panels.find((panel) => panel.code === panelCode) ?? {})
  }

  return (
    <div className="h-full w-full">
      <div className="relative grid grid-cols-2 gap-4 w-full h-full pt-16 pb-20 sm:pt-4 sm:pb-24">
        <div className="relative col-span-2 lg:col-span-1 w-full h-full px-4 py-8">
          <FilterablePanelsTable panels={Panels} setSelectedCardAndOpenModal={setSelectedCardAndOpenModal} />
        </div>
        <div className="relative hidden lg:block col-span-1 w-full h-full pr-4 py-8">
          <CollectionGuide />
        </div>
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} selectedCard={selectedCard} panel={panel} />
      </div >
    </div >
  );
}

export default App;